import React, { useState, useEffect, useMemo } from "react";

function Countdown() {
    const [timeLeft, setTimeLeft] = useState("");
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const midnightUTC = new Date(
          Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0)
        );
        const diff = midnightUTC.getTime() - now.getTime();
  
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
  
        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  
    return <div>Time left on today's leaderboard: {timeLeft}</div>;
  }
  
  export default Countdown;