import React, { useEffect, useState } from 'react';

const CryptoBalances = () => {
  const [cryptoBalances, setCryptoBalances] = useState(null);

  useEffect(() => {
    const fetchCryptoBalances = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + '/admin/withdraws/crypto', { credentials: 'include' });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCryptoBalances(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
        // Handle the error state accordingly
      }
    };

    fetchCryptoBalances();
  }, []);

  return (
    <div>
      {cryptoBalances ? (
        <ul>
          {Object.keys(cryptoBalances).map((key) => {
            if (key.includes('_address')) return null; // Skip the address keys
            return (
              <li key={key}>
                <span>{key.toUpperCase()}: </span>
                <span>{cryptoBalances[key]}</span>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CryptoBalances;
