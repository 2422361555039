import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { backendRequestAndNotification } from '../api'

const Holds = ({ws}) => {

    const [holds, setHolds] = useState([])

    const [modal, setModal] = useState(false)
    const [code, setCode] = useState('')

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getHolds() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/offers/holds`, { credentials: 'include' });
                const data = await res.json();
            
                setHolds(data)
            } catch (e) {
                return
            }
        }
        
        getHolds()
    }, [])

    useEffect(() => {
        if (ws) {
          ws.on(`admin_holds_update`, (data) => {
                if (data.type === 'new') {
                    setHolds([data.hold, ...holds])
                } else {
                    let index = holds.findIndex(message => message._id === data._id)
    
                    if (index >= 0) {
                        setHolds([
                            ...holds.slice(0, index),
                            ...holds.slice(index + 1)
                        ])
                    }
                }
            })
        }

        return () => {
            if (ws) {
                ws.off('admin_holds_update')
            }
        }
    }, [ws, holds])

    return (
        <>
            {modal && (
                <div className='modal-container' onClick={() => setModal(false)}>
                    <div className='modal-center' onClick={(e) => e.stopPropagation()}>
                        <input className='gift-input' placeholder='Giftcard Code' value={code} onChange={e => setCode(e.target.value)}/>

                        <div className='buttons'>
                            <button className='reject' onClick={() => setModal(false)}>Cancel</button>
                            <button className='send' onClick={() => {
                                let data = backendRequestAndNotification('/admin/withdraws/accept', 'POST', { _id: modal, code: code })
                                if (data.type === 'success') {
                                    setModal(false)
                                    setCode('')
                                }
                            }}>
                                Send Card
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='users-container'>
                <div className='table'>
                    <div className='table-header'>
                        <p>Username</p>
                        <p>DB ID</p>
                        <p>Wall</p>
                        <p>Amount</p>
                        <p>Offer Name</p>
                        <p>Offer ID</p>
                        <p>Releases</p>
                        <p>IP</p>
                        <p>Manage</p>
                    </div>

                    {Array.isArray(holds) && holds.map((hold, index) => (
                        <div className='table-row' key={hold._id}>
                            <div className='name clickable' onClick={() => redirectToPage(`admin/users/${hold.userid}/offers`)}>
                                <img className='avatar' src={hold.avatar} alt=''/>
                                <p>{hold.username}</p>
                            </div>
                            <p>{hold._id}</p>
                            <p>{hold.wall}</p>
                            <p>{hold.points}</p>
                            <p>{hold.offer_name}</p>
                            <p>{hold.offer_id}</p>
                            <p>{(new Date(hold.releases))?.toLocaleString()}</p>
                            <p>{hold.user_ip}</p>

                            <div className='buttons'>
                                <button className='reject' onClick={() => {
                                    backendRequestAndNotification('/admin/offers/void', 'POST', { _id: hold._id })
                                }}>Void</button>

                                <button className='send' onClick={() => {
                                    backendRequestAndNotification('/admin/offers/release', 'POST', { _id: hold._id })
                                }}>
                                    Release
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }

                .name:hover {
                    color: #4F8DDE;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .buttons {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                }

                .reject, .send, .edit {
                    border: unset;
                    outline: unset;
                    color: white;
                    border-radius: 3px;
                    cursor: pointer;
                    flex: 1;
                }

                .edit {
                    background-color: #4DAEFF;
                }

                .reject {
                    background-color: #ac4f4f;
                }

                .send {
                    background-color: #4FAC7C;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .modal-container {
                    position: fixed;
                    display: flex;

                    width: 100%;
                    height: 100%;

                    background-color: rgba(0,0,0,0.3);
                    justify-content: center;
                    align-items: center;

                    top: 0;
                    left: 0;

                    z-index: 10;
                }

                .modal-center {
                    background-color: #21294D;

                    width: 250px;
                    height: 150px;
                    border-radius: 12px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    gap: 10px;
                }

                .modal-center .buttons { 
                    width: 100%;
                    padding: 0px 25px;
                    border-box: box-sizing;
                }

                .gift-input {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    padding: 6px;
                }
            `}</style>
        </>
    )
}

export default Holds