import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { useState } from "react";

const Shop = ({ user, settings, ws }) => {
  return (
    <>
      <div className="shop-container">
        <div className="title">
          <span className="blue">
            <FontAwesomeIcon icon={faNoteSticky} />
          </span>
          <p>Terms of Service</p>
          <p>Last updated: 2023-03-17 12:00 UTC</p>
        </div>

        <p>
          Welcome to Grindbux.com. If you continue to browse and use this
          website, you agree to comply with and be bound by the following terms
          and conditions of use, which, together with our privacy policy, govern
          grindbux.com's relationship with you. If you disagree with any part of
          these terms and conditions, please discontinue the use of our website.
          grindbux.com (hereinafter referred to as 'us', 'we', or 'our'), the
          term 'you' refers to the user or viewer of our website.
          <ol>
            <li>
              Terms by accessing our services on this site, you agree to the
              terms and conditions laid out here. You also agree to comply with
              all applicable laws and regulations, and you are responsible for
              compliance with any local laws. This site is protected by
              applicable copyright law. We reserve the right to refuse service
              to anyone without reason at any time.
            </li>

            <li>
              Prohibited uses: This site may not be used for unlawful purposes.
              <br />
              Such as;
              <ul>
                <li>Money laundering</li>
                <li>Fraud</li>
              </ul>
            </li>

            <li>
              Site chat: The chat feature on the site may not be used for the
              following: harassing other users or making them feel unwelcome.
              Toxicity, racism, discrimination, advertising or spamming. Asking
              other users or staff for money (begging). Sharing pornographic or
              deep web links. Website staff have the final judgement on whether
              these rules are broken.
            </li>

            <li>
              Permitted usage: You may only use our services if you are of 13
              years of age with parental consent or 16 years of age; we reserve
              the right to terminate your access to our services if we have
              reasonable evidence or suspicion that you (the account holder) is
              under the age of permitted usage
            </li>

            <li>
              Referral code: We reserve the right to revoke users' referral
              links/promo codes. This would usually only be done when a user
              takes a code commonly used by another person, such as YouTubers or
              online celebrities.
            </li>

            <li>
              Account bans: We reserve the right to temporarily or permanently
              ban users anytime. Any activity we deem suspicious or potentially
              fraudulent would warrant an account freeze.
              <br />
              Such as;
              <ul>
                <li>Multiple accounts per household</li>
                <li>Suspicious ISP, IP, or general location</li>
                <li>
                  Signs of fraudulent activity (i.e. Completing offers multiple
                  times on other OfferWalls)
                </li>
                <li>VPN or Proxy usage</li>
                <li>Completing offers that have a high rate of chargeback</li>
                <li>Completing surveys or offers rapidly</li>
                <li>Using an online device emulator</li>
                <li>Completing offers on another users account</li>
                <li>Completing suspicious offers *</li>
              </ul>
            </li>

            <li>
              Offer holds: We reserve the right to hold offer credits at any
              time temporarily. This will occur automatically if a survey
              exceeds a user's credit limit, which is 4000 credits for 30 days
              for new users. We reserve the right to hold these credits for up
              to 90 days after offer completion; offers are released based on
              site activity. **
              <br />
              Such as;
              <ul>
                <li>
                  Offers that have high chargeback rates will not be released
                </li>
                <li>
                  Offers that are being fraudulently abused will not be released
                </li>
              </ul>
            </li>

            <li>
              On-site currency: All 'currency' values on this site are
              represented by diamonds. Diamonds do not represent any real-life
              value and can only be used in exchange for our withdrawal options.
            </li>

            <li>
              Refund policy: You may receive a refund if the site has made an
              error. Any other error, such as with a withdrawal partner or
              mistakes inputting withdrawal addresses, is outside our control
              and is not eligible for a refund from us. You would need to
              contact the provider directly to receive a refund. We cannot
              refund coins for withdrawing to a banned/limited PayPal account;
              however, if you get in contact with PayPal and arrange for the
              transaction to be refunded back into our account, we will be able
              to refund your coins when we receive the funds back.
              <br />
              We will not refund;
              <ul>
                <li>
                  Incorrect withdrawal addresses to cryptocurrencies that have
                  already been processed and dispatched.
                </li>
                <li>
                  Banned or deleted email addresses which gift cards have
                  already been processed and dispatched
                </li>
              </ul>
            </li>

            <li>
              Fraud Prevention: We aim to provide our services to everyone, no
              matter where they are from; to follow applicable law and to
              prevent fraud and money laundering, we may request for you (the
              user) to follow anti-fraud procedures before funds of any type are
              dispatched to you (the user). We may ask for the following;
              <br />
              Such as;
              <ul>
                <li>ID verification</li>
                <li>Connection differentiation</li>
                <li>Device change</li>
                <li>Network differentiation</li>
              </ul>
            </li>
          </ol>
        </p>
      </div>

      <style jsx>{`
        .shop-container {
          width: 100%;
          height: 100%;

          padding: 50px 200px;

          overflow-x: hidden;
          overflow-y: auto;
        }

        .shop-container::-webkit-scrollbar {
          display: none;
        }

        .title {
          font-weight: 500;
          font-size: 24px;

          gap: 10px;
          display: flex;
          align-items: center;

          margin-bottom: 75px;
        }

        .title p {
          margin: unset;
        }

        .blue {
          color: #4daeff;
        }
      `}</style>
    </>
  );
};

export default Shop;
