import React, { useState } from 'react';
import PTCAdminPending from './ptc/pending';
import PTCAdminHistory from './ptc/history';
import PTCAdminDeposits from './ptc/deposits';

const PTCAdmin = ({ user, ws, settings }) => {
    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='users-container'>
                <div style={{display: 'flex', alignItems: 'center', gap: '1.5rem'}}>
                    <p className={`page ${tab === 0 && 'active'}`} onClick={() => setTab(0)}>Pending</p>
                    <p className={`page ${tab === 1 && 'active'}`} onClick={() => setTab(1)}>History</p>
                    <p className={`page ${tab === 2 && 'active'}`} onClick={() => setTab(2)}>Deposits</p>
                </div>

                {tab === 0 && (
                    <PTCAdminPending ws={ws}/>
                )}

                {tab === 1 && (
                    <PTCAdminHistory ws={ws}/>
                )}

                {tab === 2 && (
                    <PTCAdminDeposits/>
                )}
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                    margin: unset;
                    margin-bottom: 0.5rem;
                }

                .active {
                    color: #4DAEFF;
                    opacity: 1;
                }
            `}</style>
        </>
    );
};

export default PTCAdmin;