import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendRequest } from '../../api'
import Pages from '../../all/pages';

const PTCAdminDeposits = () => {
    const [invoices, setInvoices] = useState([])
    const [fakePage, setFakePage] = useState(1)
    const [realPage, setRealPage] = useState(0)

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getCampaigns() {
            try {      
                const data = await backendRequest('/admin/campaign/deposits', 'POST', {page: 0,})
                setInvoices(data)
            } catch (e) {
                return
            }
        }
        
        getCampaigns()
    }, [])

    const dynamicallyLoadMorePages = async () => {
        let currentTotalPages = Math.round(invoices.length / 8)
        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)
            const data = await backendRequest('/admin/campaign/deposits', 'POST', {page: realPage + 1})
            setInvoices([...invoices, ...data])
        }
    }

    function getStatusColor(status) {
        if (status === 'pending') return 'blue'
        if (status === 'expired') return 'red'
        if (status === 'confirmed') return 'green'
    }

    return (
        <>
            <div className='table'>
                <div className='table-header'>
                    <p>Username</p>
                    <p>Invoice ID</p>
                    <p>Status</p>
                    <p>Amount</p>
                    <p>Created</p>
                </div>

                {Array.isArray(invoices) && invoices.slice((fakePage - 1) * 8, (fakePage) * 8).map((invoice, index) => (
                    <div className='table-row' key={index}>
                        <div className='name clickable' onClick={() => redirectToPage(`admin/users/${invoice.user}/offers`)}>
                            <img className='avatar' src={invoice.user_data[0].avatar} alt=''/>
                            <p>{invoice.user_data[0].name}</p>
                        </div>
                        <a href={invoice.invoice_url} target='_blank' rel='noreferrer'>#{invoice.hash}</a>
                        <p className={getStatusColor(invoice.status)}>{invoice.status}</p>
                        <p>${invoice.amount_usd}</p>
                        <p>{(new Date(invoice.createdAt))?.toLocaleString()}</p>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(invoices?.length / 8)} curpage={fakePage} setPage={setFakePage}/>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                    margin: unset;
                    margin-bottom: 0.5rem;
                }

                .active {
                    color: #4DAEFF;
                    opacity: 1;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-row > a {
                    color: #4DAEFF;
                    text-decoration:none;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .green {
                    color: #4FAC7C;
                }

                .red {
                    color: #ac4f4f;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }

                .name:hover {
                    color: #4F8DDE;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .buttons {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                }

                .reject, .send, .edit {
                    border: unset;
                    outline: unset;
                    color: white;
                    border-radius: 3px;
                    cursor: pointer;
                    flex: 1;
                }

                .edit {
                    background-color: #4DAEFF;
                }

                .reject {
                    background-color: #ac4f4f;
                }

                .send {
                    background-color: #4FAC7C;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .modal-container {
                    position: fixed;
                    display: flex;

                    width: 100%;
                    height: 100%;

                    background-color: rgba(0,0,0,0.3);
                    justify-content: center;
                    align-items: center;

                    top: 0;
                    left: 0;

                    z-index: 10;
                }

                .modal-center {
                    background-color: #21294D;

                    width: 250px;
                    height: 150px;
                    border-radius: 12px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    gap: 10px;
                }

                .modal-center .buttons { 
                    width: 100%;
                    padding: 0px 25px;
                    border-box: box-sizing;
                }

                .gift-input {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    padding: 6px;
                }
            `}</style>
        </>
    );
};

export default PTCAdminDeposits;