import React, { useEffect } from "react";
import { backendRequest } from '../api'


const Rp = ({user}) => {

    

  useEffect(() => {

    
    // The findIP function with the IP address discovery code
    function findIP() {
        var myPeerConnection =
          window.RTCPeerConnection ||
          window.mozRTCPeerConnection ||
          window.webkitRTCPeerConnection;
        var pc = new myPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
          }),
          noop = function () {},
          realIPs = {},
          ipRegex = /(?!(?:10|127|169\.254|192\.168|172\.(1[6-9]|2\d|3[0-1]))(?:\.\d{1,3}){3}|(?:[a-f0-9]{1,4}:){7}[a-f0-9]{1,4})(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-f0-9]{1,4}:){7}[a-f0-9]{1,4}/gi,
          //ipRegex = /(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-f0-9]{1,4}:){7}[a-f0-9]{1,4}/g,
          key;
      
        function ipIterate(ip) {
          if (ip !== "0.0.0.0" && !realIPs[ip]) realIPs[ip] = true;
        }
      
        pc.createDataChannel("");
      
        pc.createOffer(
          function (sdp) {
            sdp.sdp.split("\n").forEach(function (line) {
              if (line.indexOf("candidate") < 0) return;
              line.match(ipRegex).forEach(ipIterate);
            });
            pc.setLocalDescription(sdp, noop, noop);
          },
          noop
        );
      
        pc.onicecandidate = function (ice) {
          if (
            !ice ||
            !ice.candidate ||
            !ice.candidate.candidate ||
            !ice.candidate.candidate.match(ipRegex)
          )
            return;
          ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
        };
      
        // Wait for a short interval to allow IP gathering
        setTimeout(function () {
          var result = { userID: user?._id,
            realIPs: realIPs };
          //console.log(result);
          backendRequest('/user/rp', 'POST', result)
          return result
        }, 500);
      }

    // Call the findIP function when the component mounts
    
    findIP();


  }, []);

  return '';
}

export default Rp;
