import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendRequest } from '../api';

const Users = ({ ws }) => {
    const [users, setUsers] = useState([]);
    const history = useNavigate();

    const redirectToPage = (url) => history('/' + url);

    useEffect(() => {
        async function getUsers() {
            try {
                const data = await backendRequest('/admin/user/staff', 'GET');
                setUsers(data);
            } catch (e) {
                setUsers([]);
                return;
            }
        }

        getUsers();
    }, []);

    return (
        <>
            <div className="users-container">
                <div className="table">
                    <div className="table-header">
                        <p>Username</p>
                        <p>User ID</p>
                        <p>Referral Code</p>
                        <p>Earned</p>
                        <p>Earn Limit</p>
                        <p>Last IP</p>
                    </div>

                    {Array.isArray(users) &&
                        users.map((user, index) => (
                            <div
                                className="table-row"
                                key={user._id}
                                onClick={() => redirectToPage(`admin/users/${user._id}/offers`)}
                            >
                                <div className="name">
                                    <img className="avatar" src={user.avatar} alt="" />
                                    <p>{user.name}</p>
                                </div>
                                <p className="userid">{user._id}</p>
                                <p>{user.affiliate_code}</p>
                                <p>{user.earned?.toFixed(0)}</p>
                                <p>{user.earn_limit}</p>
                                <p>{user.current_ip}</p>
                            </div>
                        ))}
                </div>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header,
                .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .table-row {
                    margin-top: 15px;
                    cursor: pointer;
                    transition: all 0.2s;
                }

                .table-row:hover {
                    background-color: #1e2648;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-header > * {
                    flex: 1;
                    text-justify: center;
                    opacity: 0.5;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }

                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            `}</style>
        </>
    );
};

export default Users;
