import { faGem } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Placement = ({user, place, reward}) => {
    return (
        <>
            <div className='placement'>
                <div className='top'>
                    <p className='place'>{place}th</p>

                    <p className='username'>{user?.name}</p>

                    <img className='avatar' src={user?.avatar} alt='' draggable='false'/>
                </div>

                <div className='earned'>
                    <p>Earned <span>{user?.earned?.toFixed(0)} <FontAwesomeIcon icon={faGem}/></span></p>
                    <p className='prize'>Reward ${reward}</p>
                </div>
            </div>

            <style jsx>{`
                .placement {
                    height: 100px;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 10px;

                    display: flex;
                    flex-direction: column;
                }

                .username {
                    color: #4F8DDE;
                    font-weight: 600;
                }

                .top {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

                .place {
                    min-width: 40px;
                    min-height: 40px;
                    line-height: 40px;
                    background-color: ${place < 7 ? '#007A90' : '#1D3446;'};
                    color: ${place < 7 ? '#5BE5FD' : '#3E9266;'};
                    text-align: center;
                    border-radius: 8px;
                }

                .username {
                    margin: 0px auto;
                }

                .avatar {
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
                    height: 40px;
                    border-radius: 8px;
                }

                .prize {
                    border-radius: 9px;
                    font-size: 16px;
                    color: rgb(241, 196, 117);
                    background-color: rgb(28, 35, 68);
                    padding: 3px 15px;
                    box-shadow: rgb(0 0 0 / 20%) 0px 3px 10px;
                    user-select: none;
                    margin-left: auto;
                }

                .earned {
                    margin: auto 0px;
                    color: #4F8DDE;
                    display: flex;
                    align-items: center;
                }

                .earned span {
                    color: #F1C475;
                }

                p {
                    margin: unset;
                }

                @media only screen and (max-width: 1500px) {
                    .placement {
                        height: fit-content;
                        gap: 10px;
                    }

                    .top {
                        flex-direction: column !important;
                        gap: 10px;
                    }
                }
            `}</style>
        </>
    )
}

export default Placement