import React, { useEffect, useRef } from "react";
import { useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { faCommentDots, faHeadset } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Messages from "./messages";
import SupportMessages from "./supportmessages";




const ChatContainer = ({ user, ws, show, setShow }) => {
  

  const [general, setGeneral] = useState([]);
  const [support, setSupport] = useState([]);
  const [supportLoader, setSupportLoader] = useState(0);
  const [generalLoader, setGeneralLoader] = useState(0);
  const [rules, setRules] = useState(false);
  const isWindowClient = typeof window === "object";
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [room, setRoom] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const generalRef = useRef();

  const emojiPickerStyles = {
    '--epr-bg-color': '#262e58!important',
  '--epr-category-label-bg-color': '#1c2344!important',
  '--epr-text-color': '#007aeb',
  '--epr-hover-bg-color': '#4f8dde',
  '--epr-search-input-bg-color': '#141a31',
  '--epr-picker-border-color': '#151617',
  '--epr-search-input-bg-color-active': 'var(--epr-dark)',
  '--epr-emoji-variation-indicator-color': '#4f8dde',
  '--epr-category-icon-active-color': '#3271b7',
  '--epr-skin-tone-picker-menu-color': '#22222295'
  };
  
  // Function declarations
  const toggleEmojiPicker = () => {
    setIsEmojiPickerVisible(!isEmojiPickerVisible);
  };

  const handleEmojiClick = (emojiData) => {
    const emojiToAdd = emojiData.emoji;

    // Append the selected emoji to the current message
    setMessage((prevMessage) => prevMessage + emojiToAdd);

    // Close the emoji picker
    toggleEmojiPicker();
  };

  useEffect(() => {
    // Function to handle window size change
    function setSize() {
      if (window.innerWidth <= 1300) {
        if (show !== "forced") {
          setShow(false);
        }
      }
    }

    if (isWindowClient) {
      if (window.innerWidth <= 1300) {
        if (show !== "forced") {
          setShow(false);
        }
      }
      window.addEventListener("resize", setSize);
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, show, setShow]);

  useEffect(() => {
    generalRef.current = general;
  });

  useEffect(() => {
    async function fetchSupport() {
      try {
        let res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/chat/support`,
          { credentials: "include" }
        );
        let data = await res.json();

        setSupportLoader(1);

        setSupport(data.reverse());
      } catch (e) {
        console.error(e); // Log the error
      }
    }
    fetchSupport();

    async function fetchGeneral() {
      try {
        let res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/chat/history`,
          { credentials: "include" }
        );
        let data = await res.json();

        setGeneralLoader(1);

        setGeneral(data.reverse());
      } catch (e) {
        console.error(e); // Log the error
      }
    }
    fetchGeneral();
  }, []);

  const sendMessage = (message) => {
    if (user && ws) {
      if (room === 0) {
        ws.emit("send_message", { message: message, channel: "general" });
        setMessage("");
      }

      if (room === 1) {
        ws.emit("send_message", {
          message: message,
          channel: "support",
          ownerid: user._id,
        });
        setMessage("");
      }
    } else {
      // Handle the case where user or ws is not available
      console.error("User or ws is not available"); // Log the error
    }
  };

  const handleKeyPress = (e, message) => {
    if (e.key === "Enter") {
      sendMessage(message);
    }
  };

  useEffect(() => {
    if (ws) {
      ws.on("support_message", (data) => {
        setSupport((chat) => [...chat, data]);
      });

      ws.on("chat_message", (data) => setGeneral((chat) => [...chat, data]));
      ws.on("clear_chat", () => setGeneral([]));
      ws.on("delete_message", (data) => {
        let index = generalRef.current.findIndex(
          (message) => message._id === data
        );

        if (index >= 0) {
          setGeneral([
            ...generalRef.current.slice(0, index),
            ...generalRef.current.slice(index + 1),
          ]);
        }
      });
    }

    return () => {
      if (ws) {
        ws.off("support_message");
        ws.off("chat_message");
        ws.off("clear_chat");
        ws.off("delete_message");
      }
    };
  }, [ws, setGeneral, setSupport]);

  return (
    <>
      {rules && (
        <div className="modal-container" onClick={() => setRules(false)}>
          <div className="modal-center" onClick={(e) => e.stopPropagation()}>
            <p className="blue">Chat Rules</p>
            <ul>
              <li>Do not spam.</li>
              <li>Do not beg or ask for free diamonds.</li>
              <li>Do not attack or harass other users.</li>
              <li>Be civil, use common sense.</li>
              <li>Any sort of hate speech will not be tolerated.</li>
              <li>No referral links or links to competing websites.</li>
              <li>No suspicious links (Links to weird domains / URL shorteners)</li>
            </ul>

            <p className="x" onClick={() => setRules(false)}>
              X
            </p>
          </div>
        </div>
      )}

      <div className="chat-container">
        <div className="rooms">
          <p className="room">
            {room === 0 ? (
              <span className="icon">
                <FontAwesomeIcon icon={faCommentDots} />
              </span>
            ) : (
              <span className="icon">
                <FontAwesomeIcon icon={faHeadset} />
              </span>
            )}
            {room === 0 ? "Chat" : "Support"}
          </p>

          <button className="rules" onClick={() => setRules(true)}>
            Rules
          </button>

          <button
            className="switch"
            onClick={() => {
              setRoom((room + 1) % 2);
              setGeneralLoader(1);
              setSupportLoader(1);
            }}
          >
            {room === 0 ? (
              <>
                <span>Support </span>
                <FontAwesomeIcon icon={faHeadset} />
              </>
            ) : (
              <>
                <span>Chat </span>
                <FontAwesomeIcon icon={faCommentDots} />
              </>
            )}
          </button>
        </div>

        {room === 0 ? (
          <Messages
            setMessages={setGeneral}
            messages={general}
            loaded={generalLoader}
            setLoaded={setGeneralLoader}
            ws={ws}
          />
        ) : (
          <SupportMessages
            setMessages={setSupport}
            messages={support}
            loaded={supportLoader}
            setLoaded={setSupportLoader}
            ws={ws}
          />
        )}
         {isEmojiPickerVisible && (
            <div className="emoji-picker-modal">
              <EmojiPicker className="gbPicker" onEmojiClick={handleEmojiClick} theme="dark" style={{'--epr-bg-color': '#262e58!important',
  '--epr-category-label-bg-color': '#1c2344!important',
  '--epr-text-color': '#007aeb',
  '--epr-hover-bg-color': '#4f8dde',
  '--epr-search-input-bg-color': '#141a31',
  '--epr-picker-border-color': '#151617',
  '--epr-search-input-bg-color-active': 'var(--epr-dark)',
  '--epr-emoji-variation-indicator-color': '#4f8dde',
  '--epr-category-icon-active-color': '#3271b7',
  '--epr-skin-tone-picker-menu-color': '#22222295'}} />
            </div>
          )}
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Type message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, message)}
          />
          <div className="emoji-picker-trigger" onClick={toggleEmojiPicker} style={{opacity: 0.3}}>
            🙂
          </div>
        </div>
      </div>

      <style jsx>

        {`
          .chat-container {
            min-width: 330px;
            width: 330px;
            height: 100%;
            background: #1c2344;
            box-shadow: 2px 1px 0px #191d2d;
            border-radius: 12px 0px 0px 0px;
            box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);

            display: ${show === "forced" || show ? "flex" : "none"};
            flex-direction: column;
            align-items: center;

            padding: 0px 15px 15px 15px;
          }

          .input-wrapper {
            width: 100%;
            min-height: 50px;
            height: 50px;

            border-radius: 8px;
            background-color: #161b36;

            margin-top: auto;
          }

          .input-wrapper input {
            width: 100%;
            height: 100%;
            background-color: unset;
            border: unset;
            outline: unset;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            color: white;
            font-size: 12px;

            box-sizing: border-box;
            padding: 0px 10px;
          }

          .input-wrapper input::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            color: rgba(223, 226, 239, 0.25);
            font-size: 12px;
          }

          .rooms {
            width: 100%;
            min-height: 50px;
            height: 50px;

            font-size: 14px;

            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .room {
            margin: unset;
          }

          .icon {
            color: #4daeff;
            margin-right: 7.5px;
          }

          .rules {
            height: 40px;
            width: 60px;
            background-color: #1e2a51;

            border-radius: 8px;
            font-size: 16px;
            color: #737a99;
            cursor: pointer;

            outline: unset;
            border: unset;

            text-align: center;
            line-height: 25px;

            transition: all 0.2s;
          }

          .switch {
            width: 100px;
            height: 40px;

            background-color: #1e2a51;
            border-radius: 8px;
            color: #737a99;
            cursor: pointer;
            font-size: 16px;

            outline: unset;
            border: unset;

            color: #737a99;
            transition: all 0.2s;
            user-select: none;
          }

          .rules:hover,
          .switch:hover {
            color: white !important;
            background-color: #2c3663 !important;
          }

          .modal-container {
            position: fixed;
            display: flex;

            width: 100%;
            height: 100%;

            background-color: rgba(0, 0, 0, 0.3);
            justify-content: center;
            align-items: center;

            top: 0;
            left: 0;

            z-index: 102;
          }

          

          .modal-center {
            background-color: #21294d;

            width: 500px;
            max-width: 100%;
            height: 350px;
            border-radius: 12px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: relative;

            gap: 10px;
          }

          .blue {
            color: #4daeff;
            font-size: 1.5em;
            font-weight: 600;
            margin: unset;
          }

          .x {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            margin: unset;
          }
          .emoji-picker-modal {
            display:flex;
            
            position: relative;
            margin-top: -460px !important;
            margin-right: 25px!important;
          }
          .emoji-picker-modal .epr-dark-theme > *{
            
            
            --epr-bg-color: #262e58!important;
            --epr-category-label-bg-color: #1c2344!important;
            --epr-text-color: #007aeb !important;
            --epr-hover-bg-color: #4f8dde !important;
            
            --epr-search-input-bg-color: #141a31 !important;

            --epr-picker-border-color: #151617 !important;

            --epr-search-input-bg-color-active: var(--epr-dark) !important;
            --epr-emoji-variation-indicator-color: #4f8dde !important;
            --epr-category-icon-active-color: #3271b7 !important;
            --epr-skin-tone-picker-menu-color: #22222295 !important;
        }
        

        
        .EmojiPickerReact .epr-search-container input.epr-search {
       
          border: 1px solid #141a31;
         
          color: #007aeb;
        }
          .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
            color: #007aeb!important;

          }
           
          }
          .emoji-picker-trigger {
            display:flex;
            font-size: 24px;
            padding: 0;
            float: right;
            position: sticky;
            margin-top: -43px;
            margin-right: 5px;
            cursor: pointer!important;
            
            }

            @media only screen and (max-width: 1300px) {
                .chat-container {
                  position: fixed;
                  right: 0;
                  top: 0;
                  z-index: 100;
                  width: 300px;
                  height: calc(100% - 110px);
                  border-radius: 12px 0px 0px 12px;
                  box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.3);
                }
              }
          
        `}
      </style>
    </>
  );
};

export default ChatContainer;
