import { useState, useEffect } from "react";
import Earnings from "./earning";

const ActivityBar = ({ ws }) => {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    async function getActivity() {
      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/walls/activity`, { credentials: 'include' });
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await res.json();
        setActivity(data);
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    }

    getActivity();
  }, []);

  useEffect(() => {
    if (ws) {
      const handleActivityUpdate = (data) => {
        setActivity((prevActivity) => [data, ...prevActivity]);
      };

      ws.on('activity_update', handleActivityUpdate);

      return () => {
        ws.off('activity_update', handleActivityUpdate);
      };
    }
  }, [ws]);

  return (

        <>
            <div className='activitybar-container'>
                <div className='activity-container'>
                    <div className='activity'>
                        {Array.isArray(activity) && activity.map((offer, index) => (
                            <Earnings username={offer.name} avatar={offer.avatar} title={offer.title} amount={offer.amount} key={offer._id}/>
                        ))}
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .activitybar-container {
                        width: 100%;
                        min-height: 60px;
                        height: 60px;
                        border-radius: 10px;
                        background-color: #1C2344;
                        box-shadow: 0 3px 10px rgb(0,0,0,.2);
                        padding: 0px 15px;
                    }

                    .activity-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }

                    .activity {
                        width: fit-content;
                        height: 100%;
                        gap: 10px;
                        overflow: hidden;

                        display: flex;
                        align-items: center;
                    }
                `}
            </style>
        </>
    )
}

export default ActivityBar