import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { backendRequestAndNotification } from '../api'

const Users = ({ws}) => {

    const [withdraws, setWithdraws] = useState([])

    const [modal, setModal] = useState(false)
    const [code, setCode] = useState('')

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getWithdraws() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/withdraws/pending`, { credentials: 'include' });
                const data = await res.json();
            
                setWithdraws(data)
            } catch (e) {
                setWithdraws([])
                return
            }
        }
        
        getWithdraws()
    }, [])

    useEffect(() => {
        if (ws) {
          ws.on(`admin_withdraws_update`, (data) => {
                if (data.type === 'new') {
                    setWithdraws([data.withdraw, ...withdraws])
                } else {
                    let index = withdraws.findIndex(message => message._id === data._id)
    
                    if (index >= 0) {
                        setWithdraws([
                            ...withdraws.slice(0, index),
                            ...withdraws.slice(index + 1)
                        ])
                    }
                }
            })
        }

        return () => {
            if (ws) {
                ws.off('admin_withdraws_update')
            }
        }
    }, [ws, withdraws])

    return (
        <>
            {modal && (
                <div className='modal-container' onClick={() => setModal(false)}>
                    <div className='modal-center' onClick={(e) => e.stopPropagation()}>
                        <input className='gift-input' placeholder='Giftcard Code' value={code} onChange={e => setCode(e.target.value)}/>

                        <div className='buttons'>
                            <button className='reject' onClick={() => setModal(false)}>Cancel</button>
                            <button className='send' onClick={() => {
                                let data = backendRequestAndNotification('/admin/withdraws/accept', 'POST', { _id: modal, code: code })
                                if (data.type === 'success') {
                                    setModal(false)
                                    setCode('')
                                }
                            }}>
                                Send Card
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='users-container'>
                <div className='table'>
                    <div className='table-header'>
                        <p>Username</p>
                        <p>User ID</p>
                        <p>Type</p>
                        <p>Amount</p>
                        <p>User IP</p>
                        <p>Mobile</p>
                        <p>Address</p>
                        <p>Time</p>
                        <p>Manage</p>
                    </div>

                    {Array.isArray(withdraws) && withdraws.map((withdraw, index) => (
                        <div className='table-row' key={withdraw._id}>
                            <div className='name' onClick={() => redirectToPage(`admin/users/${withdraw.userid}/offers`)}>
                                <img className='avatar' src={withdraw.avatar} alt=''/>
                                <p>{withdraw.name}</p>
                            </div>
                            <p className='userid'>{withdraw.userid}</p>
                            <p>{withdraw.type}</p>
                            <p>{withdraw.amount}</p>
                            <p  onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${withdraw?.user_ip}`, '_blank')}>{withdraw.user_ip || 'Unknown'}</p>
                            <p>{withdraw.mobile?.toString() || 'Unknown'}</p>
                            <p><a href={(withdraw.type === "Whale.io" && 'https://tronscan.org/#/address/'+withdraw.address) || (withdraw.type === "LTC" && 'https://live.blockcypher.com/ltc/address/'+withdraw.address) || (withdraw.type === "BCH" && 'https://blockchair.com/bitcoin-cash/address/'+withdraw.address) || (withdraw.type === "SOL" && 'https://solscan.io/account/'+withdraw.address) || '#'} target='_blank'>{withdraw.address || 'Unknown'}</a></p>
                            <p>{new Date(withdraw?.timestamp).toLocaleTimeString()}</p>
                            <div className='buttons'>
                                <button className='reject' onClick={() => {
                                    backendRequestAndNotification('/admin/withdraws/reject', 'POST', { _id: withdraw._id })
                                }}>Reject</button>

                                <button className={withdraw?.tag === 'crypto' || withdraw?.tag === 'csdeals' ? 'send' : 'edit'} onClick={() => {
                                    if (withdraw?.tag === 'crypto' || withdraw?.tag === 'csdeals') {
                                        backendRequestAndNotification('/admin/withdraws/accept', 'POST', { _id: withdraw._id })
                                    } else {
                                        setModal(withdraw?._id)
                                    }
                                }}>
                                    {withdraw?.tag === 'crypto' || withdraw?.tag === 'csdeals' ? 'Accept' : 'Edit'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }

                .name:hover {
                    color: #4F8DDE;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .buttons {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                }

                .reject, .send, .edit {
                    border: unset;
                    outline: unset;
                    color: white;
                    border-radius: 3px;
                    cursor: pointer;
                    flex: 1;
                }

                .edit {
                    background-color: #4DAEFF;
                }

                .reject {
                    background-color: #ac4f4f;
                }

                .send {
                    background-color: #4FAC7C;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .modal-container {
                    position: fixed;
                    display: flex;

                    width: 100%;
                    height: 100%;

                    background-color: rgba(0,0,0,0.3);
                    justify-content: center;
                    align-items: center;

                    top: 0;
                    left: 0;

                    z-index: 10;
                }

                .modal-center {
                    background-color: #21294D;

                    width: 250px;
                    height: 150px;
                    border-radius: 12px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    gap: 10px;
                }

                .modal-center .buttons { 
                    width: 100%;
                    padding: 0px 25px;
                    border-box: box-sizing;
                }

                .gift-input {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    padding: 6px;
                }
            `}</style>
        </>
    )
}

export default Users
