import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faShoppingCart,
  faTrophy,
  faUser,
  faQuestionCircle,
  faSignOut,
  /*faSyncAlt,*/ faGem,
  faHammer,
  faMessage,
  faEgg,
  faSpinner,
  faDharmachakra,
} from "@fortawesome/free-solid-svg-icons";
//import { faBell } from '@fortawesome/free-regular-svg-icons'
import {
  faTwitter,
  faDiscord,
  faSteam,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

import { useLocation, useNavigate } from "react-router-dom";



const PageNav = ({ user, show, setShow }) => {
  const history = useNavigate();
  const redirectToPage = (url) => history("/" + url);
  const location = useLocation();

  return (
    <>

      <div className="pagenav-container">
        <div className="logo-container" onClick={() => redirectToPage("")}>
          <img src="/icons/logo.png" alt="logo" />
        </div>

        <div className="pages">
          <div
            className={
              "navlink " + (location.pathname === "/earn" ? "active" : "")
            }
            onClick={() => redirectToPage("earn")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faHandHoldingDollar} />
            </span>
            <p className="link">Earn</p>
          </div>

          <div
            className={
              "navlink " + (location.pathname === "/shop" ? "active" : "")
            }
            onClick={() => redirectToPage("shop")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faShoppingCart} />
            </span>
            <p className="link">Shop</p>
          </div>

          <div
            className={
              "navlink " + (location.pathname === "/leaders" ? "active" : "")
            }
            onClick={() => redirectToPage("leaders")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faTrophy} />
            </span>
            <p className="link">Leaders</p>
          </div>

          <div
            className={
              "navlink " + (location.pathname === "/profile" ? "active" : "")
            }
            onClick={() => redirectToPage("profile")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <p className="link">Profile</p>
          </div>

          <div
            className={
              "navlink " + (location.pathname === "/help" ? "active" : "")
            }
            onClick={() => redirectToPage("help")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </span>
            <p className="link">Help</p>
          </div>

          <div
            className={"navlink chat"}
            onClick={() => setShow(show ? false : "forced")}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faMessage} />
            </span>
            <p className="link">Chat</p>
          </div>

          {/* <div className={'navlink ' + (location.pathname === '/help' ? 'active' : '')} onClick={() => redirectToPage('help')}>
                        <span className='icon'><FontAwesomeIcon icon={faQuestionCircle}/></span>
                        <p className='link'>Help</p>
                    </div> */}

          {user && user.hash && user.hash === "4f7366752a83c81ac48" && (
            <div
              className={
                "navlink " +
                (location.pathname.includes("/admin") ? "active" : "")
              }
              onClick={() => redirectToPage("admin/users")}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faHammer} />
              </span>
              <p className="link">Admin</p>
            </div>
          )}

          {/* <div className="event" onClick={() => redirectToPage("event")}>
            <FontAwesomeIcon icon={faDharmachakra} style={{color: '#F1C475'}} />
            <p>event</p>
          </div> */}
        </div>

        <div className="right">
          {user ? (
            <>
              <div className="user-container">
                <div className="avatar-container">
                  <img
                    className="avatar"
                    src={user.avatar}
                    alt=""
                    onClick={() => redirectToPage("profile")}
                  />
                  <p className="level">
                    {Math.floor(
                      (1 +
                        Math.sqrt(
                          1 + (24 * (parseFloat(user?.earned) / 10)) / 59
                        )) /
                        2
                    ) - 1}
                  </p>
                </div>

                <p className="balance">
                  {user.balance?.toFixed(0)} <FontAwesomeIcon icon={faGem} />
                </p>
              </div>

              <div className="options-container">
                {/* <div className='option currency'>
                                    <span><FontAwesomeIcon icon={faSyncAlt}/></span>
                                </div>

                                <div className='option noti'>
                                    <span><FontAwesomeIcon icon={faBell}/></span>
                                </div> */}

                <div
                  className="option logout"
                  onClick={() =>
                    (window.location =
                      process.env.REACT_APP_SERVER_URL + "/auth/logout")
                  }
                >
                  <span>
                    <FontAwesomeIcon icon={faSignOut} />
                  </span>
                </div>

                <span className="social">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    onClick={() =>
                      window.open("https://twitter.com/grindbux", "_blank")
                    }
                  />
                </span>
                <span className="social">
                  <FontAwesomeIcon
                    icon={faDiscord}
                    onClick={() =>
                      window.open(
                        "https://discord.gg/grindbux-com-931828239410794516",
                        "_blank"
                      )
                    }
                  />
                </span>
              </div>
            </>
          ) : (
            <div className="logins">
               <div className="login-label"><span style={{ color: "rgb(156, 164, 191)" }}>Signup / Login</span></div>
              <div
                className="login"
                onClick={() =>
                  (window.location =
                    process.env.REACT_APP_SERVER_URL + "/auth/steam")
                }
              >
                <FontAwesomeIcon icon={faSteam} />
              </div>

              <div
                className="login"
                onClick={() =>
                  (window.location =
                    process.env.REACT_APP_SERVER_URL + "/auth/google")
                }
              >
                <FontAwesomeIcon icon={faGoogle} />
              </div>
            </div>
          )}
        </div>
      </div>

      <style jsx>
        {`
          .event {
            cursor: pointer;
            background-color: rgba(241, 196, 117, 0.2);
            border: 1px solid rgba(241, 196, 117, 1);
            border-radius: 6px;
            height: 60px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .event p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            color: rgba(241, 196, 117, 1);
          }

          @media only screen and (max-width: 1300px) {
            .event {
              width: 60px;
            }

            .event p {
              font-size: 10px
            }
          }

          .pagenav-container {
            width: 100%;
            height: 70px;
            min-height: 70px;
            display: flex;
            margin-bottom: 15px;
            overflow: hidden;
          }

          .logo-container {
            width: 330px;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #1c2344;
            box-shadow: 0 3px 10px rgb(0, 0, 0, 20%);
            border-bottom-right-radius: 10px;

            cursor: pointer;
          }

          .logo-container img {
            height: 40px;
          }

          .pages {
            display: flex;
            height: 100%;
            align-items: flex-end;
            gap: 15px;
            margin-left: 40px;
          }

          .navlink {
            height: 60px;
            width: 70px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;

            color: #9ca4bf;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            cursor: pointer;
            user-select: none;

            transition: color 0.2s;
          }

          .icon {
            font-size: 22px;
            font-weight: 900;
          }

          .link {
            margin: unset;
            font-size: 10px;
          }

          .navlink:hover:not(.active) {
            color: white;
          }

          .active {
            color: #4f8dde;
            background-color: #1c2344;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
          }

          .active .link {
            color: white;
          }

          .right {
            margin-left: auto;
            height: 100%;
            display: flex;
            align-items: center;
            gap: 50px;

            padding: 0px 20px;
          }

          .user-container {
            gap: 20px;
            display: flex;
          }

          .avatar-container {
            position: relative;
          }

          .avatar {
            width: 45px;
            border-radius: 40%;
            cursor: pointer;
          }

          .level {
            border-radius: 8px;
            font-size: 12px;
            padding: 2px 6px;
            background-color: #2b3d6d;
            color: #629cf1;
            position: absolute;
            font-weight: 600;

            bottom: -10px;
            right: -10px;
          }

          .balance {
            color: #f1c475;
            font-size: 15px;
            font-weight: 600;
          }

          .options-container {
            display: flex;
            align-items: center;
            gap: 15px;
          }

          .social {
            font-size: 20px;
            color: #9ca4bf;
            cursor: pointer;
            transition: * 0.2s;
          }

          .social:hover {
            color: white;
          }

          .option {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            font-size: 22px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
          }

          .logout {
            background-color: #1c2344;
            color: #9ca4bf;
          }

          .noti {
            background-color: #1d3446;
            color: #419869;
          }

          .currency {
            color: #629cf1;
            background-color: #1e2a51;
          }

          .noti:hover {
            background-color: #255459;
          }

          .logins {
            display: flex;
            height: 100%;
            align-items: center;
            gap: 15px;
          }

          .login {
            width: 125px;
            height: 40px;
            background-color: #1e2a51;
            border-radius: 8px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
            transition: all 0.2s;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 25px;
            color: #9ca4bf;

            cursor: pointer;
          }

          .login:hover {
            background-color: #2b3d6d;
          }

          .logout:hover {
            color: white;
            background-color: #1e2a50;
          }

          .currency:hover {
            background-color: #2b3d6d;
          }

          @media only screen and (max-width: 1300px) {
            .pages {
              position: fixed;
              z-index: 100;
              width: calc(100% - 20px);
              height: 90px;
              background-color: #1c2344;
              border-radius: 12px;

              justify-content: center;
              justify-content: space-between;

              bottom: 10px;
              left: 10px;

              margin: unset;
              padding: 10px;
              box-sizing: border-box;
            }

            .navlink {
              height: 100%;
              flex: 1;
            }

            .navlink.active {
              background-color: #262e58;
            }

            .chat {
              display: flex;
            }
          }

          @media only screen and (max-width: 700px) {
            .logo-container {
              width: 100%;
              min-height: 75px;
            }

            .pagenav-container {
              flex-direction: column;
              height: fit-content;
              padding: 0;
              min-height: 150px;
              gap: 10px;
            }

            .right {
              height: fit-content;
              justify-content: center;
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default PageNav;
