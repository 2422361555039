import { useEffect, useState } from 'react'
import Pages from '../../all/pages'

const Offers = ({userid}) => {

    const [withdraws, setWithdraws] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getWithdraws() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/withdraws/${userid}`, { credentials: 'include' });
                const data = await res.json();
            
                setWithdraws(data)
            } catch (e) {
                return
            }
        }
        
        getWithdraws()
    }, [realPage, userid])

    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(withdraws.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/withdraws/${userid}`, { credentials: 'include' });
            setWithdraws([...withdraws, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>Address</p>
                    <p>Type</p>
                    <p>Date</p>
                    <p>Status</p>
                    <p>Amount</p>
                    <p>Processor</p>
                    <p>Mobile</p>
                    <p>Code / TX ID</p>
                </div>

                {Array.isArray(withdraws) && withdraws.slice(fakePage * 8, (fakePage + 1) * 8).map((withdraw, index) => (
                    <div className='table-row' key={withdraw._id}>
                        <p><a href={(withdraw.type === "Whale.io" && 'https://tronscan.org/#/address/'+withdraw.address) || (withdraw.type === "LTC" && 'https://live.blockcypher.com/ltc/address/'+withdraw.address) || (withdraw.type === "BCH" && 'https://blockchair.com/bitcoin-cash/address/'+withdraw.address) || (withdraw.type === "SOL" && 'https://solscan.io/account/'+withdraw.address) || '#'} target='_blank' rel='noreferrer'>{withdraw.address || 'Unknown'}</a></p>
                        <p>{withdraw.type}</p>
                        <div className='date'>
                            <p>{new Date(withdraw?.timestamp).toLocaleDateString()}</p>

                            <p className='date-hover'>
                                {new Date(withdraw?.timestamp).toLocaleTimeString()}
                            </p>
                        </div>
                        <p className={withdraw.status === 'sent' ? 'green' : 'red'}>{withdraw.status}</p>
                        <p>{withdraw.amount}</p>
                        <p>{withdraw.staff || 'Not handled'}</p>
                        <p>{withdraw.mobile?.toString() || 'false'}</p>
                        <p><a href={(withdraw.type === "Whale.io" && 'https://tronscan.org/#/transaction/'+withdraw.code) || (withdraw.type === "LTC" && 'https://live.blockcypher.com/ltc/tx/'+withdraw.code) || (withdraw.type === "BCH" && 'https://blockchair.com/bitcoin-cash/transaction/'+withdraw.code) || (withdraw.type === "SOL" && 'https://solscan.io/tx/'+withdraw.code) || '#'} target='_blank'>{withdraw.code}</a></p>
                    </div>
                ))}

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(withdraws?.length / 8)} curpage={fakePage} setPage={setFakePage}/>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .green {
                    color: #4FAC7C;
                }

                .red {
                    color: #ac4f4f;
                }

                .date {
                    cursor: pointer;
                    position: relative;
                    overflow: visible !important;
                }

                .date-hover {
                    top: 30px;
                    margin: 0px auto;
                    left: 0;
                    right: 0;
                    position: absolute;
                    display: none;

                    width: fit-content;
                    text-align: center;
                }

                .date:hover .date-hover {
                    display: block;
                }
            `}</style>
        </>
    )
}

export default Offers
