import { useState } from "react"

const Answers = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='answer-container'>
                <div className='questions'>
                    <div className={'question ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>How long do withdrawals take?</p>
                    </div>

                    <div className={'question ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Why didn't my Gift Code work?</p>
                    </div>

                    <div className={'question ' + (tab === 2 ? 'active' : '')} onClick={() => setTab(2)}>
                        <p>Why are some Gift Cards region locked?</p>
                    </div>
                </div>

                <div className='answer'>
                    {tab === 0 ? (
                        <p>
                            Withdrawal time-frames are dependent on which option you choose, Cryptocurrency usually only takes up to 2 hours to be processed, and Gift Cards (of any type) should be processed within 2 business days.
                        </p>
                    ) : tab === 1 ? (
                        <p>
                            All our gift codes come from reputable suppliers and are highly unlikely to be invalid or used prior to you receiving them. Make sure that you input them in the correct area on the application they are for. If you are certain that your gift code is invalid, contact our on site support.
                        </p>
                    ) : tab === 2 ? (
                        <p>
                            Some gift card codes like Amazon, are region locked to only North America. If you require a region locked gift card in an area that it cannot be activated in, you can contact our on site support and ask if they could get one for your specific location. We are looking to improve our gift card selection in the near future.
                        </p>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .answer-container {
                    width: 100%;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                }

                .questions {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    border-radius: 6px;

                    display: flex;
                    gap: 15px;

                    cursor: pointer;
                }

                .question {
                    height: 100%;
                    flex: 1;
                    background-color: #1C2344;
                    color: #9CA4BF;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    border-radius: 6px;

                    transition: all .2s;
                    user-select: none;
                }

                .question:hover, .active {
                    background-color: rgb(30, 42, 80);
                }

                .answer {
                    padding: 15px;
                    font-size: 15px;
                    color: rgb(151, 164, 191);
                    font-weight: 600;
                }
            `}</style>
        </>
    )
}

export default Answers