import { createNotification } from '../api'

const Offerwall = ({img, bg, link, disabled, min_level, user, setShow, bonus}) => {

    const level = (earned) => {
        return Math.floor((1+Math.sqrt(1+24*(parseFloat(earned)/10)/59))/2)-1
    }

    return (
        <>
            <div className='wall-container' onClick={() => {
                if ((disabled || !link) && !setShow) { return } 
                if (!user) { return createNotification('error', 'You must be logged in to access the offerwalls.'); }
                if (user.banned || !user.offer_id) { return createNotification('error', 'You are unable to use the offerwalls when banned.'); }
                if (user.frozen || !user.offer_id) { return createNotification('error', 'You are currently frozen. Please contact support to be unfrozen.'); }
                if (min_level && level(user?.earned) < min_level) { return createNotification('error', `You must be at least level ${min_level} to use this offerwall.`); }
                if (setShow) { return setShow(true) }

                window.open(link, '_blank')
            }}>
                <img src={img} alt='' draggable='false'/>
                <div className={'bonus ' + (bonus ? 'active' : '')}>{bonus} bonus</div>
                <div className={'disabled ' + (disabled ? 'active' : '')}>
                    <p>Temporarily Disabled</p>
                </div>
            </div>

            <style jsx>
                {`
                    .wall-container {
                        height: 125px;
                        background: ${bg};
                        border-radius: 8px;

                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        overflow: hidden;
                        cursor: pointer;

                        transition: all .3s;
                    }

                    .wall-container:hover {
                        transform: translate(0, -4px);
                    }

                    img {
                        width: 180px;
                        user-select: none;
                    }

                    .bonus {
                        display: none;
                        position: absolute;
                        top:0;
                        left:0;
                        padding: 3px 8px;
                        border-bottom-right-radius: 10px;
                        font-weight: bold;
                        background-color: red!important;
                    }
                    .bonus.active {
                        display: flex;
                    }

                    .disabled {
                        display: none;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba(0,0,0,.8);
                        font-weight: 600;
                        font-size: 1rem;
                        color: #4DAEFF;

                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        user-select: none;
                    }

                    .disabled.active {
                        display: flex;
                    }
                `}
            </style>
        </>
    )
}

export default Offerwall