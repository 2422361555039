import { faGem } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { useState } from "react"
import { backendRequestAndNotification } from '../../api/index'

const Discord = ({connected }) => {
    
    return (
        <>
            <div className='cashout-container'>
                <div className='top'>
                    <div className='cards'>
                        <div className='card'>
                            <img src='/companies/discordnitro.svg' alt=''/>
                            <p className='cost'>$10</p>
                        </div>
                    </div>
                </div>

                <div className='bottom'>
                    <div>
                        <p className='info'><span className='blue'>Fee:</span> <span className='right'>0 <FontAwesomeIcon icon={faGem}/></span></p>
                        <p className='info bg'><span className='blue'>Total Cost:</span> <span className='right'>10000 <FontAwesomeIcon icon={faGem}/></span></p>
                    </div>

                    <div className='purchase' onClick={() => {
                            backendRequestAndNotification(`/withdraw/gc`, 'POST', { type: 'nitro', amount: 10000 })
                        }}>
                        <p>Purchase</p>
                    </div>
                </div>
            </div>

            <style jsx>
                {`
                    .cashout-container {
                        min-height: 250px;
                        max-height: 250px;
                        width: 100%;
                        background-color: rgb(20, 26, 49);
                        border-radius: 8px;
                        padding: 15px;
                        font-weight: 600;

                        overflow: hidden;
                    }

                    .top {
                        width: 100%;
                        height: 140px;

                        display: inline-grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 15px;
                    }

                    .disclaimer {
                        box-sizing: border-box;
                        padding: 0px 25px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .discord {
                        color: #4F8CDD;
                        cursor: pointer;
                    }

                    .cards {
                        width: 100%;
                        height: 140px;
                        flex: 1;

                        display: inline-grid;
                        grid-template-columns: repeat(auto-fit, minmax(190px,1fr));
                        grid-gap: 15px;
                    }

                    .card {
                        height: 140px;
                        background: linear-gradient(342.04deg, rgb(142, 135, 245) -75.71%, rgb(228, 134, 246) 135.9%);;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                        position: relative;
                        transition: transform .5s;

                        cursor: pointer;
                    }

                    .classic {
                        background: linear-gradient(342.04deg, rgb(88, 88, 187) -75.71%, rgb(154, 163, 251) 135.9%);
                    }

                    .card img {
                        width: 140px;
                    }

                    .card:hover {
                        transform: scale(0.98);
                    }

                    .cost {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        margin: unset;
                    }

                    .card.active {
                        outline: 4px solid rgb(77, 169, 248);
                    }

                    .bottom {
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }

                    .info {
                        width: 280px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0px 10px;
                        margin: 3px 0px;
                    }

                    .info.bg {
                        background-color: #22294D;
                        border-radius: 6px;
                        box-shadow: 0 3px 10px rgb(0,0,0,.2);
                    }

                    .right {
                        margin-left: auto;
                    }

                    .blue {
                        color: #4DAEFF;
                    }

                    .connect {
                        background-color: #1E2A51;
                        color: #629CF1;
                        cursor: pointer;
                        width: 200px;
                        height: 40px;
                        border-radius: 6px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        transition: all .2s;
                    }

                    .connect:hover:not(.active) {
                        background-color: #2B3D6D;
                    }

                    .connect.active {
                        color: rgb(65, 152, 105);
                        background-color: rgb(29, 52, 70);
                    }

                    .purchase {
                        margin-left: auto;
                        height: 50px;
                        width: 170px;
                        background-color: #22294D;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #629CF1;
                        transition: all .2s;
                        cursor: pointer;
                    }

                    .purchase:hover {
                        background-color: #2B3D6D
                    }

                    @media only screen and (max-width: 1300px) {
                        .cashout-container {
                            flex-direction: column;
                            min-height: unset;
                            max-height: unset;
                            height: fit-content;
                            align-items: center;
                            gap: 25px;
                        }

                        .top {
                            display: block;
                            height: fit-content;
                        }

                        .cards {
                            height: fit-content;
                            min-height: unset;
                            max-height: unset;
                        }

                        .bottom {
                            height: fit-content;
                        }
                    }

                    @media only screen and (max-width: 625px) {
                        .bottom {
                            flex-direction: column;
                            align-items: center;
                            gap: 10px;
                        }

                        .purchase {
                            width: 100%;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default Discord
