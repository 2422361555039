import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { backendRequest, backendRequestAndNotification } from '../../api'
import Pages from '../../all/pages';

const PTCAdminPending = ({ ws }) => {
    const [campaigns, setCampaigns] = useState([])

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getCampaigns() {
            try {      
                const data = await backendRequest('/admin/campaign/campaigns', 'GET')
                setCampaigns(data)
            } catch (e) {
                return
            }
        }
        
        getCampaigns()
    }, [])

    useEffect(() => {
        if (ws) {
          ws.on(`admin_ptc_update`, (data) => {
                if (data.type === 'new') {
                    setCampaigns([data.hold, ...campaigns])
                } else {
                    let index = campaigns.findIndex(message => message._id === data._id)
    
                    if (index >= 0) {
                        setCampaigns([
                            ...campaigns.slice(0, index),
                            ...campaigns.slice(index + 1)
                        ])
                    }
                }
            })
        }

        return () => {
            if (ws) {
                ws.off('admin_ptc_update')
            }
        }
    }, [ws, campaigns])

    return (
        <>
            <div className='table'>
                <div className='table-header'>
                    <p>Username</p>
                    <p>Name</p>
                    <p>Description</p>
                    <p>URL</p>
                    <p>Views</p>
                    <p>CPV</p>
                    <p>Total</p>
                    <p>Created at</p>
                    <p>Manage</p>
                </div>

                {Array.isArray(campaigns) && campaigns.map((campaign, index) => (
                    <div className='table-row' key={index}>
                        <div className='name clickable' onClick={() => redirectToPage(`admin/users/${campaign.buyer_userid}/offers`)}>
                            <img className='avatar' src={campaign.buyer_avatar} alt=''/>
                            <p>{campaign.buyer_username}</p>
                        </div>
                        <p>{campaign.campaign_name}</p>
                        <p>{campaign.campaign_description}</p>
                        <a href={campaign.campaign_url} target='_blank' rel='noreferrer'>View</a>
                        <p>{campaign.campaign_uses_total}</p>
                        <p>${campaign.campaing_cpv}</p>
                        <p>{campaign.tokens_spent} AT</p>
                        <p>{(new Date(Number(campaign.campaign_created)))?.toLocaleString()}</p>

                        <div className='buttons'>
                            <button className='send' onClick={() => {
                                backendRequestAndNotification('/admin/campaign/approve', 'POST', { _id: campaign._id })
                            }}>Approve</button>

                            <button className='reject' onClick={() => {
                                backendRequestAndNotification('/admin/campaign/reject', 'POST', { _id: campaign._id })
                            }}>
                                Reject
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                    margin: unset;
                    margin-bottom: 0.5rem;
                }

                .active {
                    color: #4DAEFF;
                    opacity: 1;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-row > a {
                    color: #4DAEFF;
                    text-decoration:none;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }

                .name:hover {
                    color: #4F8DDE;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .buttons {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                }

                .reject, .send, .edit {
                    border: unset;
                    outline: unset;
                    color: white;
                    border-radius: 3px;
                    cursor: pointer;
                    flex: 1;
                }

                .edit {
                    background-color: #4DAEFF;
                }

                .reject {
                    background-color: #ac4f4f;
                }

                .send {
                    background-color: #4FAC7C;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .modal-container {
                    position: fixed;
                    display: flex;

                    width: 100%;
                    height: 100%;

                    background-color: rgba(0,0,0,0.3);
                    justify-content: center;
                    align-items: center;

                    top: 0;
                    left: 0;

                    z-index: 10;
                }

                .modal-center {
                    background-color: #21294D;

                    width: 250px;
                    height: 150px;
                    border-radius: 12px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    gap: 10px;
                }

                .modal-center .buttons { 
                    width: 100%;
                    padding: 0px 25px;
                    border-box: box-sizing;
                }

                .gift-input {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    padding: 6px;
                }
            `}</style>
        </>
    );
};

export default PTCAdminPending;