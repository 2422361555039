import { backendRequest } from '../api'
import { faGem, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
import Loader from '../all/loader'
import { useNavigate } from 'react-router-dom'


const PTCModal = ({ user, setShow, ws }) => {
    const [loading, setLoading] = useState(true);
    const [campaigns, setCampaigns] = useState([]);

    const history = useNavigate();
    const redirectToPage = (url) => history("/" + url);


    useEffect(() => {
        async function getCampaigns() {
            try {
                const data = await backendRequest('/user/ptc', 'POST');

                if(data.type === 'success') {
                    setCampaigns(data.campaigns);
                }
            } catch (e) {
                setCampaigns([]);
                return;
            } finally {
                setLoading(false);
            }
        }

        getCampaigns();
    }, []);


    useEffect(() => {
        if (ws) {
          ws.on(`campaigns_update`, (data) => {
                if (data.type === 'new') {
                    setCampaigns([data.campaign, ...campaigns])
                } else {
                    let index = campaigns.findIndex(message => message._id === data._id)
    
                    if (index >= 0) {
                        setCampaigns([
                            ...campaigns.slice(0, index),
                            ...campaigns.slice(index + 1)
                        ])
                    }
                }
            })
        }

        return () => {
            if (ws) {
                ws.off('campaigns_update')
            }
        }
    }, [ws, campaigns])

    return (
        <>
            <div className='ptc-modal' onClick={(e) => { setShow(false) }}>
                <div className='ptc-container' onClick={(e) => e.stopPropagation()}>
                    <p className='close' onClick={(e) => { setShow(false) }}>x</p>

                    <div className='modal-header'>
                        <p className='title'>GrindBux Paid-to-click (PTC)</p>

                        <button onClick={() => redirectToPage('buy-ptc')}>Buy Ad</button>
                    </div>  

                    {loading ? (<div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
                        <Loader />
                    </div>) : campaigns.length === 0 ? (<p style={{ color: '#F1C475' }}>No campaigns available.</p>) : (
                        <>
                            <div className='ptc-grid'>
                                {Array.isArray(campaigns) && campaigns.map((campaign, index) => (
                                    <div className='ptc-ad' key={index}>
                                        <div className='ptc-header'>
                                            <h4>{campaign.campaign_name}</h4>
                                            <p>
                                                {campaign.campaign_description}
                                            </p>
                                        </div>

                                        <div className='ptc-footer'>
                                            <div className='timer'>
                                                <FontAwesomeIcon icon={faStopwatch} />
                                                <p>{campaign.campaign_view_time}s</p>
                                            </div>

                                            <button className='view' onClick={() => {
                                                window.open(`/ptc/view/${campaign._id}`, '_blank');
                                            }}>
                                                View [+{campaign.campaign_reward} <FontAwesomeIcon icon={faGem} />]
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>)}
                </div>
            </div >

            <style jsx>
                {`
                    .ptc-grid {
                        display: grid;
                        grid-template-columns: repeat(1, minmax(0, 1fr));
                        gap: 16px;
                    }

                    @media only screen and (min-width: 1024px) {
                        .ptc-grid {
                            grid-template-columns: repeat(2, minmax(0, 1fr));
                            overflow-y: auto !important;
                        }
                    }

                    @media only screen and (max-width: 1024px) {
                        .ptc-container {
                            overflow: scroll;
                            overflow-y: auto;
                            max-height: 90vh;
                            margin-top: 3rem;
                            margin-bottom: 6rem;
                            overflow-y: scroll !important;
                        }
                    }

                    .modal-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .modal-header button {
                        border: unset;
                        outline: unset;
                        color: white;
                        background-color: #4DAEFF;
                        border-radius: 6px;
                        padding: 2px 10px;
                        cursor: pointer;
                        font-size: 14px;
                    }

                    .ptc-ad {
                        background-color: #262E58;

                        border-radius: 8px;
                        box-shadow: 0 3px 10px rgb(0,0,0,0.2);
                        width: 100%;
                        height: 9rem;
                        padding: 12px;
                        box-sizing: border-box;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .ptc-header h4 {
                        margin-block: 0;
                        margin-bottom: 2px;
                    }

                    .ptc-header p {
                        letter-spacing: -0.025em;
                        color: rgb(156, 164, 191);
                        font-size: 0.75rem;
                        line-height: 1rem;
                    }

                    .ptc-footer {
                        margin-top: 0.8rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .ptc-footer .timer {
                        display: flex;
                        align-items: center;
                        color: #F1C475;
                    }

                    .ptc-footer .timer p {
                        margin-left: 4px;
                        font-size: 0.95rem;
                    }

                    .view {
                        border: unset;
                        outline: unset;
                        cursor: pointer;
                        border-radius: 4px;
                        font-weight: 600;                      
                        width: 7rem;
                        height: 30px;
                        background-color: #4D3A4B;
                        transition: all 0.3s;
                        transition: transform 0.05s;
                        color: #F1C475;
                    }

                    .view:hover {
                        background-color: #5d4b5b;
                    }

                    .ptc-modal {
                        position: fixed;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0,0,0,0.3);
                        z-index: 1001;
                        top: 0;
                        left: 0;
                    }

                    @media only screen and (max-width: 1024px) {
                        .ptc-modal {
                            padding: 12px;
                        }
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 500;
                        color: #F1C475;
                    }

                    .ptc-container {
                        position: relative;
                        height: fit-content;
                        width: 600px;
                        background: #21294D;
                        border-radius: 8px;

                        position: relative;

                        display: flex;
                        flex-direction: column;

                        padding: 24px;
                        box-sizing: border-box;
                        gap: 12px;

                        overflow: hidden;
                    }

                    @media only screen and (min-width: 1024px) {
                        .ptc-container {
                            height: 94vh !important;
                        }
                    }

                    .close {
                        font-weight: 700;
                        font-size: 12px;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                    }

                    p {
                        margin: unset;
                    }
                `}
            </style>
        </>
    )
}

export default PTCModal