import { useState } from "react"

const Answers = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='answer-container'>
                <div className='questions'>
                    <div className={'question ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>How do I earn diamonds?</p>
                    </div>

                    <div className={'question ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Why are my diamonds on hold?</p>
                    </div>

                    <div className={'question ' + (tab === 2 ? 'active' : '')} onClick={() => setTab(2)}>
                        <p>Why didn't my diamonds credit?</p>
                    </div>

                    <div className={'question ' + (tab === 3 ? 'active' : '')} onClick={() => setTab(3)}>
                        <p>Why do I not get any surveys?</p>
                    </div>
                </div>

                <div className='answer'>
                    {tab === 0 ? (
                        <>
                            <p>
                                You can earn diamonds by completing offers or surveys via any of our suppliers on the earn page. Via these suppliers you can earn Diamonds from completing online surveys on:
                            </p>

                            <div className='lists'>
                                <ul>
                                    <li>CPX Research</li>
                                    <li>BitLabs</li>
                                </ul>

                                <ul>
                                    <li>Inbrain.ai</li>
                                    <li>Timewall</li>
                                </ul>
                            </div>

                            <p>
                                You can also earn Diamonds by downloading apps, completing quizzes and playing games via these Offerwalls:
                            </p>

                            <div className='lists'>
                                <ul>
                                    <li>Monlix</li>
                                    <li>Adgem</li>
                                    <li>RevU</li>
                                </ul>

                                <ul>
                                    <li>Lootably</li>
                                    <li>Torox</li>
                                    <li>AdgateMedia</li>
                                    <li>AyeTstudios</li>
                                </ul>
                            </div>
                            
                            <p>
                                You can earn diamonds also by watching videos using these Offerwalls:
                            </p>
                            <div classname='lists'>
                                <ul>
                                    <li>Cheddar.TV</li>
                                </ul>
                            </div>
                        </>
                    ) : tab === 1 ? (
                        <>
                            <p>
                                Offers or Surveys that pay over 4000 Diamonds ($4) are put on hold for 30 days automatically. We do this to prevent chargebacks that may occur within this period from affecting us financially. This usually occurs when a user has found a way to glitch or bypass an offer and receive credit without completing it fully.
                            </p>

                            <p>
                                We offer a system for users to be able to claim on-hold funds by showing proof that they completed the offer. This proof can include:
                            </p>

                            <ul>
                                <li>A screenshot showing that you downloaded an app</li>
                                <li>A screenshot showing that you purchased a required item</li>
                                <li>A screenshot showing that you meet the requirements for payout (i.e, game level)</li>
                            </ul>

                            <p>
                            Any one of these can be uploaded to <a href="https://imgur.com/upload" target="_blank" rel='noreferrer'>Imgur</a> and sent to our support chat.
                            </p>
                        </>
                    ) : tab === 2 ? (
                        <>
                            <p>
                                Your offer or survey may have not credited for a number of reasons including but not limited to:
                            </p>

                            <ul>
                                <li>You may have been disqualified from the survey/offer without your notice</li>
                                <li>The offer may have not tracked properly and not registered its completion</li>
                                <li>You may have been banned by the offerwall</li>
                                <li>You did not complete the offer correctly</li>
                            </ul>

                            <p>
                                If you believe the offer may not have been properly tracked or that you have been unfairly banned from a wall, you can contact the offerwalls directly - please see the section in help titled "Contacting Offerwalls"
                            </p>
                        </>
                    ) : tab === 3 ? (
                        <>
                            <p>
                                You may not be getting many or any surveys/offers for many reasons including but not limited to:
                            </p>

                            <ul>
                                <li>You may have completed all the available offers/surveys for that day</li>
                                <li>You may have a low quality score</li>
                                <li>You may have been banned by the offerwall</li>
                            </ul>

                            <p>
                                If you have a low quality score, you must make sure that the few surveys/offers you get, you complete successfully to avoid it getting lower. If you believe you have been unfairly banned, you can contact the offerwalls directly - please see the section in help titled "Contacting Offerwalls"
                            </p>
                        </>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .answer-container {
                    width: 100%;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                }

                .questions {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    border-radius: 6px;

                    display: flex;
                    gap: 15px;

                    cursor: pointer;
                }

                .question {
                    height: 100%;
                    flex: 1;
                    background-color: #1C2344;
                    color: #9CA4BF;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    border-radius: 6px;

                    transition: all .2s;
                    user-select: none;
                }

                .question:hover, .active {
                    background-color: rgb(30, 42, 80);
                }

                .answer {
                    padding: 15px;
                    font-size: 15px;
                    color: rgb(151, 164, 191);
                    font-weight: 600;
                }

                .answer p {
                    margin: unset;
                }

                .lists {
                    display: flex;
                }

                @media only screen and (max-width: 1100px) {
                    .help-container {
                        padding: 50px 100px;
                    }
                }
            `}</style>
        </>
    )
}

export default Answers