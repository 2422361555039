import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Question = ({icon, question, active, click}) => {
    return (
        <>
            <div className={'question-container ' + (active ? 'active' : '')} onClick={click}>
                <span className='icon'>
                    <FontAwesomeIcon icon={icon}/>
                </span>
                <p className='question'>{question}</p>
            </div>

            <style jsx>{`
                .question-container {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    background-color: #21284C;
                    color: #4F8DDE;
                    border-radius: 6px;
                    font-weight: 600;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 0px 15px;

                    cursor: pointer;
                }

                .question-container:hover, .active {
                    background-color: #2B3D6D;
                }

                .question {
                    margin-left: auto;
                    margin-right: auto;
                }

                .icon {
                    font-size: 20px;
                }
            `}</style>
        </>
    )
}

export default Question