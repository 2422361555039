import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

const Notes = ({userid, ws}) => {

    const [notes, setNotes] = useState([])
    const [note, setNote] = useState('')

    // 4f7366752a83c81ac48

    useEffect(() => {
        if (ws) {
          ws.on('notes_add', (data) => {
            if (data.userid === userid) {
                setNotes([data, ...notes])
            }
          })

          ws.on('notes_delete', (data) => {
            if (data.userid === userid) {
                let index = notes.findIndex(note => note._id === data._id)
  
                if (index >= 0) {
                    setNotes([
                        ...notes.slice(0, index),
                        ...notes.slice(index + 1)
                    ])
                }
            }
          })
        }
  
        return () => {
          if (ws) {
            ws.off('notes_add')
            ws.off('notes_delete')
          }
        }
    }, [ws, notes, userid])

    const sendNotification = (type, message) => {
        let content = {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        }

        if (!type) {
            return toast(message, content)
        }
    
        if (type === 'error') { 
            return toast.error(message, content)
        }

        if (type === 'success') {
            return toast.success(message, content)
        }
    }

    useEffect(() => {
        async function getNotes() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/notes/${userid}`, { credentials: 'include' });
                const data = await res.json();
            
                setNotes(data)
            } catch (e) {
                return
            }
        }
        
        getNotes()
    }, [userid])

    return (
        <>
            <div className='users-container'>
                <div className='input-container'>
                    <input type='string' onChange={(e) => setNote(e.target.value)} value={note} placeholder='Type a new note...'/>
                    <button className='add' onClick={async () => {
                        const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/notes/add`, {
                            method: 'POST',
                            body: JSON.stringify({ userid, message: note }),
                            headers: { 'Content-Type': 'application/json' },
                            credentials: 'include'
                        })
                        const data = await res.json()

                        if (res.status === 200) {
                            setNote('')
                            sendNotification(data.type, data.message)
                        }
                    }}>Add</button>
                </div>

                {Array.isArray(notes) && notes.map((note, index) => (
                    <div className='table-row' key={note._id}>
                        <div className='section'>
                            <img className='avatar' src={note?.avatar} alt=''/>
                            <p>{note?.author_name}</p>

                            <p className='right'>{note?.timestamp ? new Date(note?.timestamp).toLocaleString() : 'Unknown Date'}</p>
                        </div>

                        <div className='section'>
                            <p>{note.message}</p>

                            <button className='delete' onClick={async () => {
                                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/notes/delete`, {
                                    method: 'POST',
                                    body: JSON.stringify({ _id: note._id }),
                                    headers: { 'Content-Type': 'application/json' },
                                    credentials: 'include'
                                })
                                const data = await res.json()

                                if (res.status === 200) {
                                    sendNotification(data.type, data.message)
                                }
                            }}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 10px 20px;
                    font-size: 14px;
                    align-items: center;

                    margin-top: 15px;
                    transition: all .2s;
                    flex-direction: column;
                    gap: 10px;
                    word-break: break-word!important;
                }

                .section {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    word-break: break-word!important;
                }

                .avatar {
                    width: 45px;
                    border-radius: 6px;
                    margin-right: 15px;
                }

                .right {
                    margin-left: auto;
                }

                .delete {
                    margin-left: auto;
                }

                .input-container {
                    width: 100%;
                    height: 50px;
                    min-height: 50px;
                    display: flex;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 0px;
                    overflow: hidden;

                    padding: 10px 20px;
                }

                .input-container input {
                    width: 100%;
                    height: 100%;

                    outline: unset;
                    border: unset;
                    color: white;
                    background-color: unset;

                    border-radius: 6px;
                }

                .delete, .add {
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    height: 30px;
                    border-radius: 6px;
                    color: white;
                    border: unset;
                    outline: unset;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    transition: all .2s;
                }

                .add, .delete {
                    background: #22294D;
                }

                .delete:hover, .add:hover {
                    background: #2B3D6D;
                }
                
                .table-row > * {
                    word-break: break-all;
                }

                p {
                    margin: unset;
                }
            `}</style>
        </>
    )
}

export default Notes