import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Pages from '../all/pages'
import { backendRequest } from '../api'

const Offers = () => {

    const [offers, setOffers] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(1)

    const [lookup, setLookup] = useState('')
    const [type, setType] = useState('userid')

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getOffers() {
            try {
                const data = await backendRequest('/admin/offers/lookup', 'POST', {page: 0, lookup: ''})
                setOffers(data)
            } catch (e) {
                return
            }
        }
        
        getOffers()
    }, [])

    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(offers.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await backendRequest('/admin/offers/lookup', 'POST', {page: realPage + 1, lookup: lookup, type: type})
            setOffers([...offers, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>

                <div className='search'>
                    <input placeholder='Type in lookup' value={lookup} onChange={(e) => setLookup(e.target.value)}/>
                    <button onClick={async () => {
                        const data = await backendRequest('/admin/offers/lookup', 'POST', {page: realPage, lookup: lookup, type: type})
                        setOffers(data)
                        setRealPage(0)
                        setFakePage(1)
                    }}>Search</button>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option value='userid'>User ID</option>
                        <option value='username'>User Name</option>
                        <option value='offername'>Offer Name</option>
                        <option value='offerid'>Offer ID</option>
                        <option value='status'>Status</option>
                        <option value='ip'>IP</option>
                    </select>
                </div>

                <div className='table'>
                    <div className='table-header'>
                        <p>Username</p>
                        <p>Wall</p>
                        <p>Amount</p>
                        <p>Offer Name</p>
                        <p>Offer ID</p>
                        <p>Status</p>
                        <p>IP</p>
                    </div>

                    {Array.isArray(offers) && offers.slice((fakePage - 1) * 8, (fakePage) * 8).map((offer, index) => (
                        <div className='table-row' key={offer._id}>
                            <div className='name clickable' onClick={() => redirectToPage(`admin/users/${offer.userid}/offers`)}>
                                <img className='avatar' src={offer.avatar} alt=''/>
                                <p>{offer.username}</p>
                            </div>
                            <p>{offer.wall}</p>
                            <p>{offer.points}</p>
                            <p>{offer.offer_name}</p>
                            <p>{offer.offer_id}</p>
                            <p>{offer.status}</p>
                            <p className='ip clickable' onClick={() => window.open(`https://www.ipqualityscore.com/free-ip-lookup-proxy-vpn-test/lookup/${offer?.user_ip}`, '_blank')}>{offer?.user_ip}</p>
                        </div>
                    ))}
                </div>

                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(offers?.length / 8)} curpage={fakePage} setPage={setFakePage}/>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                select {
                    background: #1C2344;
                    border-radius: 6px;
                    outline: unset;
                    border: unset;
                    color: white;
                    height: 100%;
                    padding: 6px;
                    text-align: center;
                }
                
                .search {
                    display: flex;
                    margin-bottom: 15px;
                    height: 50px;
                    gap: 15px;
                }

                .search input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: #21284C;
                    padding: 0px 10px;
                    border-radius: 6px;
                }

                .search button {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    color: white;
                    background-color: #4DAEFF;
                    border-radius: 6px;
                    cursor: pointer;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .name p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .name:hover {
                    color: #4F8DDE;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            `}</style>
        </>
    )
}

export default Offers
