import { useEffect, useState } from 'react'

import Slider from '../../all/slider'
import { backendRequestAndNotification } from '../../api'

const Offers = ({userid}) => {

    const [messages, setMessages] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(0)

    useEffect(() => {
        async function getMessages() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/user/messages/${userid}`, { credentials: 'include' });
                const data = await res.json();
            
                setMessages(data)
            } catch (e) {
                return
            }
        }
        
        getMessages()
    }, [realPage, userid])

    const deleteMessage = (message, index) => {
        message.deleted = !message.deleted

        backendRequestAndNotification('/admin/chat/delete', 'POST', { id: message._id, value: message.deleted })

        setMessages([
            ...messages.slice(0, index),
            message,
            ...messages.slice(index + 1)
        ])
    }

    return (
        <>
            <div className='users-container'>
                <div className='table-header'>
                    <p>ID</p>
                    <p>Date</p>
                    <p>Message</p>
                    <p className='right'>Deleted</p>
                </div>

                {Array.isArray(messages) && messages.slice(fakePage * 8, (fakePage + 1) * 8).map((message, index) => (
                    <div className='table-row' key={message._id}>
                        <p>{message._id}</p>
                        <p>{new Date(message.timestamp).toLocaleDateString()}</p>
                        <p>{message.message}</p>
                        
                        <span className='slider-wrapper right'>
                            <Slider active={message?.deleted} click={() => deleteMessage(message,index)}/>
                        </span>
                    </div>
                ))}
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .right {
                    flex: 0.3 !important;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .slider-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            `}</style>
        </>
    )
}

export default Offers