import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { backendRequest } from '../api'
import Pages from '../all/pages'

const Users = ({ws}) => {

    const [users, setUsers] = useState([])
    // eslint-disable-next-line
    const [realPage, setRealPage] = useState(0)
    // eslint-disable-next-line
    const [fakePage, setFakePage] = useState(1)

    const [lookup, setLookup] = useState('')

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    useEffect(() => {
        async function getUsers() {
            try {
                const data = await backendRequest('/admin/user/lookup', 'POST', {page: 0, lookup: ''})
                setUsers(data)
            } catch (e) {
                setUsers([])
                return
            }
        }
        
        getUsers()
    }, [])

    const dynamicallyLoadMorePages = async () => {

        let currentTotalPages = Math.round(users.length / 8)

        if (currentTotalPages - 3 === fakePage) {
            setRealPage(realPage + 1)

            const data = await backendRequest('/admin/user/lookup', 'POST', {page: realPage + 1, lookup: ''})
            setUsers([...users, ...data])

        }
    }

    return (
        <>
            <div className='users-container'>

                <div className='search'>
                    <input placeholder='Lookup username or id' value={lookup} onChange={(e) => setLookup(e.target.value)}/>
                    <button onClick={async () => {
                        const data = await backendRequest('/admin/user/lookup', 'POST', {page: realPage, lookup: lookup})
                        setUsers(data)
                        setRealPage(0)
                    }}>Search</button>
                </div>

                <div className='table'>
                    <div className='table-header'>
                        <p>Username</p>
                        <p>User ID</p>
                        <p>Referral Code</p>
                        <p>Earned</p>
                        <p>Earn Limit</p>
                        <p>Last IP</p>
                    </div>

                    {Array.isArray(users) && users.slice((fakePage - 1) * 8, fakePage * 8).map((user, index) => (
                        <div className='table-row' key={user._id} onClick={() => redirectToPage(`admin/users/${user._id}/offers`)}>
                            <div className='name'>
                                <img className='avatar' src={user.avatar} alt=''/>
                                <p>{user.name}</p>
                            </div>
                            <p className='userid' onClick={(e) => e.stopPropagation()}>{user._id}</p>
                            <p onClick={(e) => e.stopPropagation()}>{user.affiliate_code}</p>
                            <p>{user.earned?.toFixed(0)}</p>
                            <p>{user.earn_limit}</p>
                            <p onClick={(e) => e.stopPropagation()}>{user.current_ip}</p>
                        </div>
                    ))}
                </div>
                
                <Pages loadMorePages={dynamicallyLoadMorePages} maxPages={Math.ceil(users?.length / 8)} curpage={fakePage} setPage={setFakePage}/>
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table {
                    overflow-x: auto;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                    min-width: 675px;
                }

                .search {
                    display: flex;
                    margin-bottom: 15px;
                    height: 50px;
                    gap: 15px;
                }

                .search input {
                    border: unset;
                    outline: unset;
                    width: 100%;
                    color: white;
                    background-color: #21284C;
                    padding: 0px 10px;
                    border-radius: 6px;
                }

                .search button {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    color: white;
                    background-color: #4DAEFF;
                    border-radius: 6px;
                    cursor: pointer;
                }

                .table-row {
                    margin-top: 15px;
                    cursor: pointer;
                    transition: all .2s;
                }

                .table-row:hover {
                    background-color: #1e2648;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .green {
                    color: #4FAC7C;
                }

                .blue {
                    color: #4F8DDE;
                }

                .clickable {
                    cursor: pointer;
                }

                .name {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    overflow: hidden;
                }
                
                .avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            `}</style>
        </>
    )
}

export default Users