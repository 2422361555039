import { useState } from "react"

const Answers = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='answer-container'>
                <div className='questions'>
                    <div className={'question ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>User</p>
                    </div>

                    <div className={'question ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Advertiser</p>
                    </div>
                </div>
                
                <div className='answer'>
                    {tab === 0 ? (
                        <>
                            <p>
                                Why didn’t I get credited?
                            </p>
                            <div className='lists'>
                                <ul>
                                    <li>You must click “Claim” within 2 minutes of watching the full advertisement.</li>
                                    <li>You must be focused on the advertisement window, not another tab.</li>
                                </ul>
                            </div>

                            <div className='qwa'>
                                <p className="qwa-question">
                                How often can I view advertisements?
                                </p>
                                <p className="qwa-answer">You can view each advertisement once in a 24 hour period.</p>
                            </div>
                        </>
                    ) : tab === 1 ? (
                        <>
                            <p><span style={{color: '#F1C475'}}>TIP:</span> We recommend checking <a href="https://iframetester.com/" target="_blank" className='url' rel="noreferrer">here</a> to see if your website can load inside an iframe before purchasing a PTC campaign.</p>

                            <div className='qwa'>
                                <p className="qwa-question">
                                Why can’t I create a campaign? 
                                </p>
                                <p className="qwa-answer">You must spend a minimum of 200 ad tokens for one campaign </p>
                            </div>

                      
                            <div className='qwa'>
                                <p className="qwa-question">
                                How long does it take for approval of advertisement? 
                                </p>
                                <p className="qwa-answer">An admin must approve your advertisement before it is shown to users. This can take up to a few hours.</p>
                            </div>


                            <div className='lists'>
                                <p>What content is not allowed to advertise?</p>
                                <ul>
                                    <li>No pornography</li>
                                    <li>No illegal products or services</li>
                                    <li>No frame breakers/websites that never load</li>
                                </ul>
                            </div>

                            <div className='qwa'>
                                <p className="qwa-question">
                                What payment methods do you accept? 
                                </p>
                                <p className="qwa-answer">For now we accept LTC, BCH and SOL, with a minimum deposit of $1. If you would like to pay through another method please open a support ticket and we will try to accommodate your request as best as possible.
You may also use your diamonds to create campaigns.</p>
                            </div>

                            <div className='qwa'>
                                <p className="qwa-question">
                                Where is my deposit?
                                </p>
                                <p className="qwa-answer">Once you have deposited and funds have confirmed, please go to the deposit tab and press "confirm" button in order to have funds added to your account.</p>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .lists p {
                    margin-bottom:0;
                }

                .lists ul {
                    margin-top:0;
                }

                .url {
                    color: #4DAEFF !important;
                    text-decoration: none;
                }

                .qwa .qwa-question {
                    margin-bottom: 0
                }

                .qwa .qwa-answer {
                    margin-top: 0
                }

                .answer-container {
                    width: 100%;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                }

                .questions {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    border-radius: 6px;

                    display: flex;
                    gap: 15px;

                    cursor: pointer;
                }

                .question {
                    height: 100%;
                    flex: 1;
                    background-color: #1C2344;
                    color: #9CA4BF;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    border-radius: 6px;

                    transition: all .2s;
                    user-select: none;
                }

                .question:hover, .active {
                    background-color: rgb(30, 42, 80);
                }

                .answer {
                    padding: 15px;
                    font-size: 15px;
                    color: rgb(151, 164, 191);
                    font-weight: 600;
                }
            `}</style>
        </>
    )
}

export default Answers
