import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

const Tab = ({title, first, second, link}) => {
    
    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    return (
        <>
            <div className='tab-container' onClick={() => { if (link !== null) redirectToPage(link) }}>
                <p className='title'>{title?.split(' ')[0]} <span className='blue'>{title?.split(' ')[1]}</span></p>

                <div className='icons'>
                    <FontAwesomeIcon icon={first}/>
                    <FontAwesomeIcon icon={second}/>
                </div>
            </div>

            <style jsx>
                {`
                    .tab-container {
                        flex: 1;
                        min-width: 210px;
                        min-height: 150px;
                        height: 150px;
                        background-color: #141A31;
                        border-radius: 12px;
                        background: rgba(9, 13, 21, 0.28);
                        border: 2px solid #181C2E;

                        gap: 10px;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transition: all .3s;

                        cursor: ${link != null ? 'pointer' : 'inherit'};
                    }

                    .tab-container:hover {
                        transform: translate(0, -4px);
                    }

                    p {
                        margin: unset;
                    }

                    .title {
                        font-weight: 600;
                        font-size: 20px;
                    }

                    .blue {
                        color: #4DAEFF;
                    }

                    .icons {
                        font-size: 50px;
                        display: flex;
                        gap: 15px;
                    }
                `}
            </style>
        </>
    )
}

export default Tab