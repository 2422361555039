// Nav
import PageNav from "./nav/pagenav";

// Pages
import Landing from "../pages/landing";
import Earn from "../pages/earn";
import Shop from "../pages/shop";
import Leaders from "../pages/leaders";
import Profile from "../pages/profile";
import Help from "../pages/help";
import Admin from "../pages/admin";
import TOS from "../pages/tos";
import Privacy from "../pages/privacy";
import User from "../components/admin/user";

// Components & Statics

import Chat from "./chat/chatcontainer2";
import ActivityBar from "./activity/activitybar";

import { useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import io from "socket.io-client";
import useVideoCard from "use-videocard";
import crypto from "crypto-js";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Affiliate from "../pages/affiliate";
import { backendRequest } from "./api";
import ViewPTC from "./view-ptc";
import BuyPTC from "../pages/ptc";
import Spin from "../pages/spin";

const Center = () => {
  const [ws, setWs] = useState(null);
  const [user, setUser] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [settings, setSettings] = useState(null);
  const [announcement, setAnnouncement] = useState(null);
  const location = useLocation()

  const [show, setShow] = useState(true);

  const graphics = useVideoCard();
  const graphicsStatic = useRef(null);

  const sendNotification = (type, message) => {
    let content = {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    };

    if (!type) {
      return toast(message, content);
    }

    if (type === "error") {
      return toast.error(message, content);
    }

    if (type === "success") {
      return toast.success(message, content);
    }
  };

  useEffect(() => {
    async function getUser() {
      try {
        const res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/auth/user`,
          { credentials: "include" }
        );
        const data = await res.json();

        if (!data.time_till_daily) {
          data.daily_claim_time = Date.now();
        } else {
          data.daily_claim_time = Date.now() + data.time_till_daily;
        }

        setUser(data);
        setFetched(true);

        try {
          if (data) {
            const affiliateCode = JSON.parse(
              localStorage.getItem("affiliate-code")
            );

            if (data.created_at > affiliateCode.timestamp) {
              const res = await backendRequest("/affiliates/register", "POST", {
                code: affiliateCode.code,
                user_id: data._id,
              });
              if (res.type !== "error") {
                localStorage.removeItem("affiliate-code");
              }
            }
          }
        } catch (e) {
          console.log("e", e);
        }
      } catch (e) {
        setUser(null);
        setFetched(true);
        return;
      }
    }

    getUser();
  }, []);

  useEffect(() => {
    async function getSettings() {
      try {
        const res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/settings`,
          { credentials: "include" }
        );
        const data = await res.json();

        setSettings(data);
      } catch (e) {
        setSettings(null);
        return;
      }
    }

    async function getAnnouncement() {
      try {
        const res = await fetch(
          process.env.REACT_APP_SERVER_URL + `/announcement`,
        );
        const data = await res.json();

        setAnnouncement(data);
      } catch (e) {
        setAnnouncement(null);
        return;
      }
    }

    getSettings();
    getAnnouncement();
  }, []);

  useEffect(() => {
    let tempWs = io(process.env.REACT_APP_SERVER_URL + "", {
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionAttempts: 10,
    });
    let hasConnected = false;

    tempWs.on("connect", () => {
      if (!hasConnected && graphicsStatic.current) {
        setTimeout(async () => {
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const fpPromise = FingerprintJS.load();
          let { visitorId } = await fpPromise.then((fp) => fp.get());
          let hash = crypto
            .HmacSHA256(
              graphicsStatic.current.vendor +
              "-" +
              graphicsStatic.current.renderer +
              "-" +
              visitorId +
              "-" +
              userTimezone,
              "somesuperinsanelystrongkey"
            )
            .toString();

          tempWs.emit("device_information", {
            gpu_vendor: graphicsStatic.current.vendor,
            gpu: graphicsStatic.current.renderer,
            fingerprint: visitorId,
            timezone: userTimezone,
            hash: hash
          });
        }, 1000);

        hasConnected = true;
      }

      console.log("Connected to WS");
    });

    tempWs.on("disconnect", () => {
      setWs(null);

      let retrying = setInterval(() => {
        tempWs = io(process.env.REACT_APP_SERVER_URL, {
          transports: ["websocket", "polling"],
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionAttempts: 10,
        });
        setWs(tempWs);
        clearInterval(retrying);
      }, 1000);
    });

    tempWs.on("notification", (data) => {
      sendNotification(data.type, data.message);
    });

    tempWs.on("settings", (data) => {
      setSettings(data);
    });

    tempWs.on("announcement", (data) => {
      setAnnouncement(data);
    });

    tempWs.on("user_update", (user) => {
      if (!user) {
        return;
      }

      if (!user.time_till_daily) {
        user.daily_claim_time = Date.now();
      } else {
        user.daily_claim_time = Date.now() + user.time_till_daily;
      }

      setUser(user);
    });
    tempWs.on("balance_update", (newBal) => {
      setUser((user) => {
        delete user.balance;
        return { balance: newBal, ...user };
      });
    });

    tempWs.on("ad_balance_update", (newBal) => {
      setUser((user) => {
        delete user?.adtoken_balance;
        return { adtoken_balance: newBal, ...user };
      });
    });

    setWs(tempWs);

    return () => {
      if (tempWs) {
        tempWs.off("notification");
        tempWs.off("settings");
        tempWs.off("announcement");
        tempWs.off("user_update");
        tempWs.off("balance_update");
      }
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (graphics && graphics.unmasked) {
        graphicsStatic.current = graphics.unmasked;
      }
    };

    fetchData();
  }, [graphics]);

  if (!location.pathname.startsWith('/ptc/view/')) {
    return (
      <>
        {settings?.maintenance && (!user || !user.rank || user?.rank < 3) ? (
          <div className="maintenance">
            <img src="/icons/maintenance.png" alt="Maintenance" />
            <p>
              Stay up to date on our{" "}
              <span
                className="discord"
                onClick={() =>
                  window.open(
                    "https://discord.gg/grindbux-com-931828239410794516",
                    "_blank"
                  )
                }
              >
                Discord
              </span>
            </p>
          </div>
        ) : (
          <>
            <PageNav user={user} show={show} setShow={setShow} />

            <div className="horizontal-center-container">
              <div className="vertical-center-container">
                <ActivityBar ws={ws} />

                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route
                    exact
                    path="/earn"
                    element={<Earn user={user} settings={settings} ws={ws} announcement={announcement} />}
                  />

                  {/* <Route
                    exact
                    path="/event"
                    element={<Spin user={user} settings={settings} ws={ws}/>}
                  /> */}

                  <Route
                    exact
                    path="/shop"
                    element={<Shop settings={settings} ws={ws} user={user} />}
                  />
                  <Route exact path="/leaders" element={<Leaders />} />

                  {user && (
                    <>
                      <Route
                        exact
                        path="/profile"
                        element={<Profile user={user} />}
                      />

                      <Route
                        exact
                        path="/buy-ptc"
                        element={<BuyPTC user={user} ws={ws} />}
                      />
                      {/* <Route exact path="/test/:code" element={<TestPage />} /> */}
                    </>
                  )}

                  <Route exact path="/help" element={<Help />} />
                  <Route exact path="/tos" element={<TOS />} />
                  <Route exact path="/privacy" element={<Privacy />} />

                  {user &&
                    user.rank >= 3 &&
                    user.hash === "4f7366752a83c81ac48" && (
                      <>
                        <Route
                          exact
                          path="/admin/:subpage"
                          element={
                            <Admin user={user} settings={settings} ws={ws} announcement={announcement} />
                          }
                        />
                        <Route
                          exact
                          path="/admin/users/:userid/:subpage"
                          element={<User ws={ws} />}
                        />
                        <Route
                          exact
                          path="/admin"
                          element={<Navigate to="/admin/users" replace />}
                        />
                      </>
                    )}

                  <Route exact path="/ref/:code" element={<Affiliate />} />

                  {fetched ? (
                    <Route path="*" element={<Navigate to="/" replace />} />
                  ) : null}
                </Routes>
              </div>

              <Chat ws={ws} user={user} show={show} setShow={setShow} />
            </div>
          </>
        )}

        <style jsx>{`
          .horizontal-center-container {
            height: 100%;
            width: 100vw;
            display: flex;
            overflow: hidden;
          }

          .vertical-center-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 0px 15px;
          }

          .maintenance {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          .maintenance img {
            width: 100%; 
            max-width: 500px;
          }

          .discord {
            color: #7288dc;
            cursor: pointer;
          }

          @media only screen and (max-width: 1300px) {
            .vertical-center-container {
              padding: 0px 15px 90px 15px;
            }
          }
        `}</style>
      </>
    )
  } else {
    return (<>
      <Routes>
        <Route exact path="/ptc/view/:code?" element={<ViewPTC user={user} ws={ws}/>}/>
      </Routes>
    </>)
  }
};

export default Center;
