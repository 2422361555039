import { useState } from "react"

const Answers = () => {

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='answer-container'>
                <div className='questions'>
                    <div className={'question ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>
                        <p>Contact us via Live Chat</p>
                    </div>

                    <div className={'question ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>
                        <p>Contact us via Email</p>
                    </div>

                    <div className={'question ' + (tab === 2 ? 'active' : '')} onClick={() => setTab(2)}>
                        <p>Contact us via Social Media</p>
                    </div>
                </div>
                
                <div className='answer'>
                    {tab === 0 ? (
                        <p>
                            Click the support icon next to the "chat rules" button on the top right of the page.
                        </p>
                    ) : tab === 1 ? (
                        <p>
                            Click <a href="mailto:support@grindbux.com">here to send us an email</a>.
                        </p>
                    ) : tab === 2 ? (
                        <p>
                            Click here to message us via <a href="https://twitter.com/grindbux">Twitter</a>, please note this is not checked as often as email or live support, so expect longer delays. 
                        </p>
                    ) : null}
                </div>
            </div>

            <style jsx>{`
                .answer-container {
                    width: 100%;
                    background-color: #141A31;
                    border-radius: 12px;
                    padding: 15px;
                }

                .questions {
                    width: 100%;
                    height: 60px;
                    transition: all 0.3s ease-in-out;
                    border-radius: 6px;

                    display: flex;
                    gap: 15px;

                    cursor: pointer;
                }

                .question {
                    height: 100%;
                    flex: 1;
                    background-color: #1C2344;
                    color: #9CA4BF;
                    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    border-radius: 6px;

                    transition: all .2s;
                    user-select: none;
                }

                .question:hover, .active {
                    background-color: rgb(30, 42, 80);
                }

                .answer {
                    padding: 15px;
                    font-size: 15px;
                    color: rgb(151, 164, 191);
                    font-weight: 600;
                }
            `}</style>
        </>
    )
}

export default Answers
