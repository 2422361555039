import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Shop = ({ user, settings, ws }) => {
  return (
    <div className="shop-container">
      <div className="title">
        <span className="blue">
          <FontAwesomeIcon icon={faNoteSticky} />
        </span>
<p class="title">Privacy Policy</p>
<p>Last updated: 2023-08-03</p>
</div>
<p>
  GrindBux is committed to providing quality services to you and this
  policy outlines our ongoing obligations to you in respect of how we
  manage your Personal Information.
</p>
<p>
  At Grindbux.com ("Grindbux," "we," "us," or "our"), we are committed to protecting your privacy and ensuring the security of your information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide when using our website and services.
</p>

<p class="title">1. Information Collected</p>
<p>
  1.1. Non-Personally Identifiable Information: When you visit Grindbux.com, we automatically collect certain non-personally identifiable information, including your IP address, browser type, operating system, and the pages you visit. This information is used to improve the functionality and performance of our website.
</p>
<p>
  1.2. Account Information: To use certain features of Grindbux.com, you may need to create an account. We collect minimal account information, which does not include personally identifying data. This data may include a username, email address (for account verification purposes), and non-sensitive information related to your activities on our platform.
</p>

<p class="title">2. Use of Information</p>
<p>
  2.1. Non-Personally Identifiable Information: We use non-personally identifiable information to analyze website usage patterns, track user interactions, and improve our services and user experience.
</p>
<p>
  2.2. Account Information: The account information we collect is used solely for the purpose of providing our services and ensuring the security of your account. We do not share, sell, or disclose this information to third parties, except as required by law or outlined in this Privacy Policy.
</p>

<p class="title">3. Data Security</p>
<p>
  3.1. Encryption: We take data security seriously and implement industry-standard encryption protocols to protect all information transmitted to and from our servers. This includes using secure sockets layer (SSL) technology for data transmission.
</p>
<p>
  3.2. Storage: All account information is securely stored on our servers. We do not store any personally identifying data. In the event that we need to store non-sensitive information for user convenience, it will always be encrypted.
</p>

<p class="title">4. Cookies and Tracking Technologies</p>
<p>
  4.1. Cookies: Grindbux.com may use cookies and similar tracking technologies to enhance your user experience. Cookies are small data files stored on your device that help us recognize you on subsequent visits and remember your preferences.
</p>
<p>
  4.2. Opt-Out: You can configure your browser to refuse cookies or alert you when they are being sent. However, disabling cookies may limit your access to certain features of the website.
</p>

<p class="title">5. Third-Party Services</p>
<p>
  5.1. Third-Party Links: Grindbux.com may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party websites. We encourage you to review their respective privacy policies before providing any information.
</p>

<p class="title">6. Children's Privacy</p>
<p>
  6.1. Grindbux.com is not intended for use by individuals under the age of 13 without parental consent. If you are under 13 years of age, you must obtain permission from your parent or legal guardian before using our services. We do not knowingly collect any personally identifiable information from children under 13 years of age without parental consent. If you believe your child under 13 has provided us with personal information without parental consent, please contact us, and we will promptly delete the information.
</p>

<p class="title">7. Changes to this Privacy Policy</p>
<p>
  7.1. We reserve the right to modify this Privacy Policy at any time. If we make material changes, we will notify you through the website or via email. Continued use of Grindbux.com after any changes to the Privacy Policy will indicate your acceptance of the updated terms.
</p>

<p class="title">8. Contact Information</p>
<p>
  8.1. If you have any questions or concerns about this Privacy Policy or the practices of Grindbux.com, please contact us at <a href="mailto:support@grindbux.com">support@grindbux.com</a>.
</p>

<p>
  By using Grindbux.com, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.
</p>
<p class="title">Policy Updates</p>
<p>
  This Policy may change from time to time and is available on our
  website.
</p>
      
      <style jsx>{`
        .shop-container {
          width: 100%;
          height: 100%;

          padding: 50px 200px;

          overflow-x: hidden;
          overflow-y: auto;
        }

        .shop-container::-webkit-scrollbar {
          display: none;
        }

        .title {
          font-weight: 500;
          font-size: 24px;

          gap: 10px;
          display: flex;
          align-items: center;

          margin-bottom: 75px;
        }

        .title p {
          margin: unset;
        }

        .blue {
          color: #4daeff;
        }
      `}</style>
    </div>
  );
};

export default Shop;
