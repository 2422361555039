import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { backendRequestAndNotification } from '../api';

const Promocodes = ({ws}) => {

    const [promocodes, setPromocodes] = useState([])

    const [code, setCode] = useState('')
    const [worth, setWorth] = useState(0)
    const [uses, setUses] = useState(0)

    // useEffect(() => {
    //     if (ws) {
    //       ws.on('promocodes_update', (data) => {
    //         if (data.userid === userid) {
    //             let index = notes.findIndex(note => note._id === data._id)
  
    //             if (index >= 0) {
    //                 setNotes([
    //                     ...notes.slice(0, index),
    //                     ...notes.slice(index + 1)
    //                 ])
    //             }
    //         }
    //       })
    //     }
  
    //     return () => {
    //       if (ws) {
    //         ws.off('promocodes_update')
    //       }
    //     }
    // }, [ws, notes, userid])

    useEffect(() => {
        async function getCodes() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/promocodes/get`, { credentials: 'include' });
                const data = await res.json();
            
                setPromocodes(data)
            } catch (e) {
                return
            }
        }
        
        getCodes()
    }, [])

    const sendNotification = (type, message) => {
        let content = {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        }

        if (!type) {
            return toast(message, content)
        }
    
        if (type === 'error') { 
            return toast.error(message, content)
        }

        if (type === 'success') {
            return toast.success(message, content)
        }
    }

    return (
        <>
        <div style={{overflow: 'scroll'}}>
            <div className='users-container'>
                <div className='input-container'>
                    <input type='string' onChange={(e) => setCode(e.target.value)} value={code} placeholder='Promocode Name'/>

                    <input type='number' onChange={(e) => setWorth(e.target.value)} value={worth} placeholder='Promocode Worth'/>

                    <input type='number' onChange={(e) => setUses(e.target.value)} value={uses} placeholder='Promocode Uses'/>

                    <button className='add' onClick={() => {
                        backendRequestAndNotification('/admin/promocodes/create', 'POST', {
                            code,
                            worth: parseInt(worth),
                            uses: parseInt(uses)
                        })
                    }}>Add</button>
                </div>

                {Array.isArray(promocodes) && promocodes.map((note, index) => (
                    <div className='table-row' key={note?._id}>
                        <p>{note?.code}</p>
                        <p>{note?.uses} uses</p>
                        <p>{note?.worth} diamonds</p>
                        <p>{note?.users.length} used</p>

                        <p>{note?.timestamp ? new Date(note?.timestamp).toLocaleString() : 'Unknown Date'}</p>
                        <button className='delete' onClick={async () => {
                                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/admin/promocodes/delete`, {
                                    method: 'POST',
                                    body: JSON.stringify({ _id: note?._id }),
                                    headers: { 'Content-Type': 'application/json' },
                                    credentials: 'include'
                                })
                                const data = await res.json()

                                if (res.status === 200) {
                                    sendNotification(data.type, data.message)
                                }
                            }}>Delete</button>
                    </div>
                ))}
            </div>
        </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 10px 20px;
                    font-size: 14px;
                    align-items: center;

                    margin-top: 15px;
                    transition: all .2s;
                    gap: 10px;

                    justify-content: space-between;
                }

                .section {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .avatar {
                    width: 45px;
                    border-radius: 6px;
                    margin-right: 15px;
                }

                .right {
                    margin-left: auto;
                }

                .delete {
                    margin-left: auto;
                }

                .input-container {
                    width: 100%;
                    height: 50px;
                    min-height: 50px;
                    display: flex;
                    padding: 0px;
                    overflow: hidden;

                    gap: 10px;
                }

                .input-container input {
                    width: 100%;
                    height: 100%;

                    outline: unset;
                    border: unset;
                    color: white;
                    background-color: #141A31;

                    padding: 0px 10px;

                    border-radius: 12px;
                }

                .delete {
                    margin-left: auto;
                }

                .delete, .add {
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    height: 100%;
                    border-radius: 12px;
                    color: white;
                    border: unset;
                    outline: unset;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    transition: all .2s;
                }

                .delete:hover, .add:hover {
                    background: #2B3D6D;
                }

                .add, .delete {
                    background: #22294D;
                }

                .delete:hover, .add:hover {
                    background: #2B3D6D;
                }
                
                .table-row > * {
                    word-break: break-all;
                }

                p {
                    margin: unset;
                }
            `}</style>
        </>
    )
}

export default Promocodes
