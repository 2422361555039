import { faHammer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useLocation, useNavigate } from 'react-router-dom'

import Offers from "../components/admin/offers"
import Reversals from "../components/admin/reversals"
import Holds from "../components/admin/holds"
import Settings from "../components/admin/settings"
import Withdraws from "../components/admin/withdraws"

import Users from '../components/admin/users'
import Support from "../components/admin/support"
import Promocodes from "../components/admin/promocodes"
import Rp2 from "../components/all/rp2"
import Staff from "../components/admin/staff"
import CryptoBalances from "../components/admin/balance"
import PTCAdmin from "../components/admin/ptc"
import Test from "../components/admin/test"
//import RealIP from "../components/all/rp"

const Admin = ({settings, ws, user, announcement}) => {

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)
    const location = useLocation()

    const [page, setPage] = useState(0)

    let { subpage } = useParams() 

    useEffect(() => {
        switch (subpage?.toLowerCase()) {
            case 'users':
                setPage(0)
                break
            case 'withdraws':
                setPage(1)
                break
            case 'offers':
                setPage(2)
                break
            case 'holds':
                setPage(3)
                break
            case 'support':
                setPage(4)
                break
            case 'reversals':
                setPage(5)
                break
            case 'promocodes':
                setPage(6)
                break
            case 'settings':
                setPage(7)
                break
            case 'ptc':
                setPage(8)
                break
            case 'staff':
                setPage(9)
                break
            case 'balance':
                setPage(10)
                break
                case 'test':
                    setPage(11)
                    break

            default:
                window.location = window.location.protocol + '//' + window.location.host + '/earn'
                break
        }
    }, [history, location, subpage])

    return (
        <>
            <div className='admin-container'>
                <div className='title'>
                    <span className='blue'><FontAwesomeIcon icon={faHammer}/></span>
                    <p>Admin</p>
                </div>

                <div className='nav'>
                    <p className={'page ' + (page === 0 ? 'active' : '')} onClick={() => redirectToPage('admin/users')}>Users</p>
                    <p className={'page ' + (page === 1 ? 'active' : '')} onClick={() => redirectToPage('admin/withdraws')}>Withdrawals</p>
                    <p className={'page ' + (page === 2 ? 'active' : '')} onClick={() => redirectToPage('admin/offers')}>Offers</p>
                    <p className={'page ' + (page === 3 ? 'active' : '')} onClick={() => redirectToPage('admin/holds')}>Holds</p>
                    <p className={'page ' + (page === 4 ? 'active' : '')} onClick={() => redirectToPage('admin/support')}>Support</p>
                    <p className={'page ' + (page === 5 ? 'active' : '')} onClick={() => redirectToPage('admin/reversals')}>Reversals</p>
                    <p className={'page ' + (page === 6 ? 'active' : '')} onClick={() => redirectToPage('admin/promocodes')}>Promocodes</p>
                    <p className={'page ' + (page === 7 ? 'active' : '')} onClick={() => redirectToPage('admin/settings')}>Settings</p>
                    <p className={'page ' + (page === 11 ? 'active' : '')} onClick={() => redirectToPage('admin/test')}>Test Page</p>
                    <p className={'page ' + (page === 8 ? 'active' : '')} onClick={() => redirectToPage('admin/ptc')}>PTC</p>
                    <p className={'page ' + (page === 9 ? 'active' : '')} onClick={() => redirectToPage('admin/staff')}>Staff</p>
                    <p className={'page ' + (page === 10 ? 'active' : '')} onClick={() => redirectToPage('admin/balance')}>Balances</p>
                </div>

                {page === 0 && (
                    <Users user={user} settings={settings} ws={ws}/>
                )}

                {page === 1 && (
                    <Withdraws ws={ws}/>
                )}

                {page === 2 && (
                    <Offers ws={ws}/>
                )}

                {page === 3 && (
                    <Holds ws={ws}/>
                )}

                {page === 4 && (
                    <Support user={user} settings={settings} ws={ws}/>
                )}
                {page === 5 && (
                    <Reversals ws={ws}/>
                )}
                {page === 6 && (
                    <Promocodes ws={ws}/>
                )}

                {page === 7 && (
                    <Settings user={user} settings={settings} ws={ws} announcement={announcement}/>
                )}

                {page === 8 && (
                    <PTCAdmin user={user} settings={settings} ws={ws}/>
                )}
                
                {page === 9 && (
                    <Staff user={user} settings={settings} ws={ws}/>
                )}

                {page === 10 && (
                    <CryptoBalances user={user} settings={settings} ws={ws}/>
                )}

{page === 11 && (
                    <Test user={user} settings={settings} ws={ws}/>
                )}

            <div>
      
  <Rp2 />
    </div>
            </div>

            <style jsx>{`
                .admin-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .admin-container::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    font-weight: 500;
                    font-size: 24px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                .title p {
                    margin: unset;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                }

                .active, .blue {
                    color: #4DAEFF;
                    opacity: 1;
                }

                .nav {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 15px;
                    row-gap: 10px;
                    user-select: none;
                }

                p {
                    margin: unset;
                }

                @media only screen and (max-width: 1300px) {
                    .admin-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 870px) {
                    .admin-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 870px) {
                    .admin-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 400px) {
                    .admin-container {
                        padding: 50px 25px;
                    }
                }

                @media only screen and (max-width: 290px) {
                    .admin-container {
                        padding: 50px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default Admin