import { faGem } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Countdown from "react-countdown";
import useWindowFocus from "use-window-focus";
import useTabActive from '../hooks/use-tab-active';
import DisableDevtool from 'disable-devtool';
import { toast } from 'react-toastify';
import { backendRequest } from "./api";

const ViewPTC = ({ user, ws }) => {
    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)
    const { code } = useParams();
    const [loading, setLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    const [viewed, setViewed] = useState(false);
    const [reward, setReward] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const iframeRef = useRef(null);
    const [iframeUrl, setIframeUrl] = useState(null);
    const toastStyle = {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'colored',
    }

    function generateRandomString() {
        let randomValues = new Uint8Array(10);
        window.crypto.getRandomValues(randomValues);
        return btoa(String.fromCharCode.apply(null, randomValues));
    }

    function knuthShuffle(arr) {
        var rand, temp, i;
        for (i = arr.length - 1; i > 0; i -= 1) {
            rand = Math.floor((i + 1) * Math.random());
            temp = arr[rand];
            arr[rand] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }

    DisableDevtool();

    const windowFocused = useWindowFocus();
    const tabActive = useTabActive();

    let countdownApi = null;
    const setRef = (countdown) => {
        if (countdown) {
            countdownApi = countdown.getApi();
        }
    };

    // Check focus
    useEffect(() => {
        console.log("window:", windowFocused, "tab:", tabActive);
        if ((windowFocused && tabActive) || isFocused) {
            setIsPaused(false);
            countdownApi && countdownApi.start();
        } else {
            setIsPaused(true);
            countdownApi && countdownApi.pause();
        }
    }, [windowFocused, tabActive, isFocused]);


    // Countdown timer
    useEffect(() => {
        let interval;
        if (!isPaused) {
            interval = setInterval(() => {
                setCounter(counter => counter - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isPaused]);

    // Fetch campaign details
    useEffect(() => {
        let data = {};
        for (let i = 0; i < 12; i++) {
            data[generateRandomString().replace('==', '')] = btoa(code);
        }

        const payload = knuthShuffle({ 'Bxo6J8csBy5f3w': btoa(code), ...data })
        const sendToken = async () => {
            if(!code) {
                return redirectToPage('earn')
            }

            const fetchDetails = await backendRequest('/user/ppc', 'POST', { ...payload })
            if (fetchDetails.type === 'error') {
                toast.error(fetchDetails.message, toastStyle)

                return redirectToPage('earn');
            }

            console.log('user', user)
            console.log('ws', ws)
            setReward(Number(fetchDetails.reward));
            setCounter(Number(fetchDetails.timer));
            setIframeUrl(fetchDetails.url);
            setLoading(false);
        };

        sendToken();
    }, []);


    // Claim tokens
    const handleClick = async () => {
        let data = {};
        for (let i = 0; i < 12; i++) {
            data[generateRandomString().replace('==', '')] = btoa(code);
        }
        const payload = knuthShuffle({ 'TCxvVc3pmFNR4Q': btoa(code), ...data })
        const processPtc = await backendRequest('/user/ppcu', 'POST', { ...payload })
        if(processPtc.type === 'success') {
            return window.location.replace(iframeUrl)
        }
        if (processPtc.type === 'error') {
            toast.error(processPtc.message, toastStyle)
            return redirectToPage('earn');
        }
    
    };

    return (
        <>
            <div className="navbar">
                <div className="logo">
                    <img src="/icons/logo.png" alt="logo" />
                </div>

                {loading ? (
                    <p style={{ color: "white" }}>Loading...</p>
                ) : viewed ? (
                    <div>
                        <button className="claim" onClick={() => handleClick()}>
                            Claim +{reward} <FontAwesomeIcon icon={faGem} />
                        </button>
                    </div>
                ) : (
                    <Countdown
                        ref={setRef}
                        date={Date.now() + counter * 1000}
                        intervalDelay={0}
                        precision={1000}
                        onComplete={() => setViewed(true)}
                        renderer={(props) => (
                            <div>
                                Time left: {Math.round(props.total / 1000)}{" "}
                                seconds
                            </div>
                        )}
                    />
                )}
            </div>

            <div className="content">
                {!loading ? (
                    <iframe src={iframeUrl} title="Preview" ref={iframeRef} onMouseEnter={() => setIsFocused(true)}
                        onMouseLeave={() => setIsFocused(false)} />
                ) : (
                    <p style={{ margin: "1rem" }}>Loading...</p>
                )}
            </div>

            <style jsx>{`
                .content {
                    height: 100%;
                }

                .content iframe {
                    border: unset;
                    width: 100%;
                    height: 100%;
                }

                .navbar {
                    width: 100%;
                    height: 6rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 2rem;
                    background-color: #1c2344;
                }

                .logo img {
                    height: 35px;
                }

                .claim {
                    border: unset;
                    outline: unset;
                    width: 150px;
                    height: 45px;
                    color: white;
                    background-color: #4daeff;
                    border-radius: 6px;
                    cursor: pointer;
                }

                @media only screen and (max-width: 700px) {
                    .navbar {
                        height: 10rem;
                        justify-content: center;
                        flex-direction: column;
                    }

                    .logo {
                        margin-bottom: 0.25rem;
                    }
                }
            `}</style>
        </>
    );
};

export default ViewPTC;
