import Tab from '../components/landing/tab'
import Stat from '../components/landing/stat'
import Section from '../components/landing/section'

import { faPoll, faCloudDownload, faMobileAlt, faMouse, faKeyboard, faMoneyBillWave, faGift, faCreditCard, faUsers, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faPaypal, faBitcoin, faEthereum } from '@fortawesome/free-brands-svg-icons'
import { useState, useEffect } from 'react'

const Landing = () => {

    const [stats, setStats] = useState()

    useEffect(() => {
        async function getStats() {
            try {
                const res = await fetch(process.env.REACT_APP_SERVER_URL + `/statistics/overall`, { credentials: 'include' });
                const data = await res.json();
            
                setStats(data)
            } catch (e) {
                return
            }
        }
        
        getStats()
    }, [])

    return (
        <>
            <div className='landing-container'>
                <span>
                    <h1 className='slogan'>Earn free crypto, Paypal, and gift cards</h1>
                    <p className='cta'>Join thousands of users already earning hundreds of dollars every day!</p>
                </span>

                <Section>
                    <Tab title='Complete Surveys' first={faClipboard} second={faPoll} link='earn'/>
                    <Tab title='Download Apps' first={faCloudDownload} second={faMobileAlt} link='earn'/>
                    <Tab title='Play Games' first={faKeyboard} second={faMouse} link='earn'/>
                </Section>

                <p className='divider'>- And Cashout Into -</p>

                <Section>
                    <Tab title='Crypto Currencies' first={faBitcoin} second={faEthereum} link='shop'/>
                    <Tab title='Paypal Money' first={faPaypal} second={faMoneyBillWave} link='shop'/>
                    <Tab title='Gift Cards' first={faGift} second={faCreditCard} link='shop'/>
                </Section>

                <Section>
                    <Stat title='Completed Offers' icon={faPoll} stat={stats?.offers_completed || 0}/>
                    <Stat title='Total Earned' icon={faMoneyBillWave} stat={(stats?.total_earned / 1000).toFixed(2) || 0}/>
                    <Stat title='Registered Users' icon={faUsers} stat={stats?.users || 0}/>
                    <Stat title='Online Users' icon={faUserFriends} stat={stats?.online || 0}/>
                </Section>
                <div className='links-container'>
        <a href="https://www.grindbux.com/tos" target="_blank" className='link'>Terms of Service</a>
         / 
        <a href="https://www.grindbux.com/privacy" target="_blank" className='link'>Privacy Policy</a>
      </div>
            </div>

            <style jsx>{`
                    .links-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: xx-small;
                        color: #fff;
                        margin-top: 20px;
                      }
              
                      .link {
                        color: #fff;
                        text-decoration: underline;
                        margin: 0 5px;
                      }

                   
                .landing-container {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;

                    align-items: center;
                    padding: 50px 200px;
                    gap: 25px;

                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .landing-container::-webkit-scrollbar {
                    display: none;
                }

                .cta, .slogan {
                    text-align: center;
                }

                .slogan {
                    font-weight: 600;
                    font-size: 40px;
                    color: #4DAEFF;
                    margin: unset;
                }

                span {
                    margin-bottom: 25px;
                }

                .divider {
                    color: #9CA4BF;
                }

                .cta {
                    font-weight: 500;
                    font-size: 20px;
                    margin: unset;
                }

                @media only screen and (max-width: 1100px) {
                    .landing-container {
                        padding: 50px 100px;
                    }
                }

                @media only screen and (max-width: 870px) {
                    .landing-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 870px) {
                    .landing-container {
                        padding: 50px;
                    }
                }

                @media only screen and (max-width: 400px) {
                    .landing-container {
                        padding: 50px 25px;
                    }
                }

                @media only screen and (max-width: 290px) {
                    .landing-container {
                        padding: 50px 0px;
                    }
                }
            `}</style>
        </>
    )
}

export default Landing