import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Warnings = ({user}) => {

    const history = useNavigate()
    const redirectToPage = (url) => history('/' + url)

    const [tab, setTab] = useState(0)

    return (
        <>
            <div className='users-container'>
                <div className='nav'>
                    <p className={'page ' + (tab === 0 ? 'active' : '')} onClick={() => setTab(0)}>Fingerprint Alts</p>
                    <p className={'page ' + (tab === 1 ? 'active' : '')} onClick={() => setTab(1)}>Address Alts</p>
                </div>
                
                <div className='table-header'>
                    <p>Alt</p>
                </div>

                {tab === 0 ? (
                    <>
                        {Array.isArray(user.possible_alts) && user.possible_alts?.slice(0,100).map((userid, index) => (
                            <div className='table-row' key={userid + index}>
                                <p className='userid' onClick={() => redirectToPage(`admin/users/${userid}/offers`)}><a href={'/admin/users/'+userid+'/offers'}>{userid}</a></p>
                            </div>
                        ))}
                    </>
                ) : tab === 1 ? (
                    <>
                        {Array.isArray(user.withdraw_alts) && user.withdraw_alts?.map((userid, index) => (
                            <div className='table-row' key={userid}>
                                <p className='userid' onClick={() => redirectToPage(`admin/users/${userid}/offers`)}><a href={'/admin/users/'+userid+'/offers'}>{userid}</a></p>
                            </div>
                        ))}
                    </>
                ) : null}
            </div>

            <style jsx>{`
                .users-container {
                    width: 100%;
                    overflow: hidden;
                }

                .title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    color: #F1C475;
                    margin: unset;
                }

                .table-header, .table-row {
                    background-color: #141A31;
                    display: flex;
                    border-radius: 12px;
                    padding: 0px 15px;
                    font-size: 14px;
                    align-items: center;
                }

                .table-row {
                    margin-top: 15px;
                    transition: all .2s;
                }

                .table-row > * {
                    flex: 1;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .table-header > * {
                    flex: 1;
                    text-align: center;
                    opacity: 0.5;
                }

                .userid {
                    cursor: pointer;
                }

                .userid:hover {
                    color: #4F8DDE;
                }

                .page {
                    opacity: 0.5;
                    cursor: pointer;
                }

                .active, .blue {
                    color: #4DAEFF;
                    opacity: 1;
                }

                .nav {
                    display: flex;
                    gap: 15px;
                    user-select: none;
                }
            `}</style>
        </>
    )
}

export default Warnings